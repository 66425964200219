import { openDB } from 'idb';

const DATABASE_NAME = 'LYCOMMS_SYNC_DATA';
const DATABASE_VERSION = 10;

/**
 * Initialize the IndexedDB.
 * see https://developers.google.com/web/ilt/pwa/lab-indexeddb
 * for information as to why we use switch w/o breaks for migrations.
 * add do the database version and add a switch case each time you need to
 * change migrations
 */
const db = openDB(DATABASE_NAME, DATABASE_VERSION,  {
    upgrade(db, oldVersion, newVersion, transaction) {
      /* eslint-disable */
      switch (oldVersion) {
        case 0:
          // a placeholder case so that the switch block will
          // execute when the database is first created
          // (oldVersion is 0)
          db.createObjectStore('Inspections', { keyPath: 'id' });
          db.createObjectStore('WorkOrders', { keyPath: 'id' });
          db.createObjectStore('Tracks', { keyPath: 'id' });
          db.createObjectStore('Assets', { keyPath: 'id' });
          db.createObjectStore('Priorities', { keyPath: 'id' });
          db.createObjectStore('Statuses', { keyPath: 'id' });
        case 1:
          db.createObjectStore('Changelog', { keyPath: 'uuid' });
          const chitx = transaction.objectStore('Changelog');
          chitx.createIndex('DefectDetailChange', [ 'object_type', 'object_id', 'action', 'field_key' ]); // Fields Only
          chitx.createIndex('NewDefectPhotos', [ 'object_type', 'object_id', 'action', 'uuid' ]);
          chitx.createIndex('NewDefectNotes', [ 'object_type', 'object_id', 'action', 'uuid' ]);
          chitx.createIndex('InspectionChange', [ 'object_type', 'object_id', 'action', 'uuid' ]); // Notes
          chitx.createIndex('WorkOrderChange', [ 'object_type', 'object_id', 'action', 'uuid' ]); // Notes & Images
        case 2:
          const tritx = transaction.objectStore('Tracks');
          tritx.createIndex('siteIndex', 'site'); // Tracks by Site
        case 3:
          const assitx = transaction.objectStore('Assets');
          assitx.createIndex('siteIndex', 'site'); // Assets by Site
        case 4:          
          db.createObjectStore('User', { keyPath: 'id' });
        case 5:
          const woitx = transaction.objectStore('WorkOrders');
          woitx.createIndex('siteIndex', 'site'); // WOs by Site
        case 6:
          db.deleteObjectStore('Changelog');
          db.createObjectStore('Changelog', { autoIncrement: true });
          const ch2itx = transaction.objectStore('WorkOrders');
          ch2itx.createIndex('InspectionUpdates', [ 'change_type', 'inspection', 'note_title' ]);
          ch2itx.createIndex('WorkOrderUpdates', [ 'change_type', 'work_order_id' ]);
          ch2itx.createIndex('DefectUpdates', [ 'change_type', 'defect', 'item' ]);
        case 7:
          const ch3itx = transaction.objectStore('Changelog');
          ch3itx.createIndex('uniqueId', 'uuid'); 
        case 8:
          db.deleteObjectStore('WorkOrders');
          db.createObjectStore('WorkOrders', { keyPath: 'id' });
          const wo1itx = transaction.objectStore('WorkOrders');
          wo1itx.createIndex('siteIndex', 'site');
        case 9:          
          const ch4itx = transaction.objectStore('Changelog');
          ch4itx.createIndex('InspectionUpdates', [ 'change_type', 'inspection', 'note_title' ]);
          ch4itx.createIndex('WorkOrderUpdates', [ 'change_type', 'work_order_id' ]);
          ch4itx.createIndex('DefectUpdates', [ 'change_type', 'defect', 'item' ]);

      }
    }
});

class DBService {

  async get(tablespace, key) {//console.log('key => ');console.log(key);
    return db.then(db => {
      return db.get(tablespace, key);
    }).catch(error => {
      console.log('DBService - get - ' + error);
    });
  }

  async getKeyFromIndex(tablespace, indexName, key) {//console.log('keyAll => ');console.log(key);
    return db.then(db => {
      return db.getKeyFromIndex(tablespace, indexName, key);
    }).catch(error => {
      console.log('DBService - getKeyFromIndex - ' + error);
    });
  }

  async getAllFromIndex(tablespace, indexName, key) {//console.log('keyAll => ');console.log(key);
    return db.then(db => {
      return db.getAllFromIndex(tablespace, indexName, key);
    }).catch(error => {
      console.log('DBService - getAllFromIndex - ' + error);
    });
  }

  async getAll(tablespace, index = []) {
    return db.then(db => {
      return db.getAll(tablespace);
    }).catch(error => {
      console.log('DBService - getAll - ' + error);
    });
  }

  async put(tablespace, object, key = null) {
    return db.then(db => {
      if (key) {
        return db.put(tablespace, object, key);
      }
      return db.put(tablespace, object);
    }).catch(error => {
      console.log('DBService - put - ' + error);
    });
  }

  async delete(tablespace, key) {
    return db.then(db => {
      return db.delete(tablespace, key);
    }).catch(error => {
      console.log('DBService - delete - ' + error);
    });
  }

  async deleteKeyFromIndex(tablespace, indexName, key) {//console.log('deleteKeyFromIndex => ');console.log(key);
    return db.then(db => {
      db.getKeyFromIndex(tablespace, indexName, key).then((primaryKey) => {
        return db.delete(tablespace, primaryKey);
      });      
    }).catch(error => {
      console.log('DBService - deleteKeyFromIndex - ' + error);
    });
  }

  async deleteAll(tablespace) {
    return db.then(db => {
      return db.clear(tablespace);
    }).catch(error => {
      console.log('DBService - deleteAll - ' + error);
    });
  }

  async eraseDatabase() {
    return db.then(db => {
      var tables = [ 'User', 'Assets', 'Changelog', 'Inspections', 'Priorities', 'Statuses', 'Tracks', 'WorkOrders' ];
      
      {
        const tx = db.transaction(db.objectStoreNames, 'readwrite');

        const clearTableSpaces = Promise.all([ 
          tables.map((t) => {//console.log('clearing - ' + t);
            tx.objectStore(t).clear();
          }),
          tx.done
        ]);

        clearTableSpaces.then((values) => {//console.log('erase complete');
          return values[1];
        });
      }

    }).catch(error => {
      console.log('DBService - eraseDatabase - ' + error);
    });
  }
}

export const Service = new DBService()