import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { Assignment, CheckCircle, PlayCircleOutline } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';

class WorkOrderList extends React.Component {

  generateURI(workOrderId) {
    return "/workorders/" + workOrderId;
  }

  render() {
    return (
      <List>
        {
          (this.props.workorders) && (this.props.workorders.length > 0) ?
            this.props.workorders.map(wo => {
              return (
                <ListItem key={'wo-' + wo.id} component={Link} to={this.generateURI(wo.id)}>
                  <ListItemIcon>
                    <Assignment />
                  </ListItemIcon>
                  <ListItemText primary={wo.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="go to">                    
                    {
                      wo.inspection_status ? 
                      <CheckCircle color="primary" /> : <PlayCircleOutline />
                    }
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }) : <ListItem key='wo-0' color="textPrimary"><ListItemText primary='No Work Orders found.' /></ListItem>
        }
      </List>
    );
  }
}

export default withRouter(WorkOrderList);