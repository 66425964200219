import React from "react";
import { Container, Box, AppBar, BottomNavigation, BottomNavigationAction, Grid, Paper, Typography, Card, CardHeader, CardContent, List, ListItem, ListItemText, Avatar, CircularProgress } from '@material-ui/core';
import { AssignmentReturn, NoteAdd, Beenhere } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import InspectionTrackList from './InspectionTrackList';
import WorkOrderList from './WorkOrderList';
import { withRouter } from "react-router";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import PageTitle from "./PageTitle";

const useStyles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0
  },
  detailsHeader: {
    'font-size': '1em',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.54)' 
  },
  hasIcon: {
    'vertical-align': 'middle',
    display: 'inline-flex',
    'align-items': 'center',
    gap: '6px'
  },
  listTitle: {
    padding: '12px 12px 0 12px!important'
  },
  listTitleHeader: {
    'margin-bottom': '0!important'
  },
  listTitleIconButton: {
    'padding': '0 0 0 12px!important'
  }
});

class InspectionTracks extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      inspection: null,
      tracks: [],
      workorders: []
    }

    this._getInspectionData = this._getInspectionData.bind(this);
    this._goToVerify = this._goToVerify.bind(this);
    this._goToInspections = this._goToInspections.bind(this);
    this._addInspectionNote = this._addInspectionNote.bind(this);
    this._addDefect = this._addDefect.bind(this);
  }

  _goToVerify() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/verify');
  }

  _goToInspections() {
    this.props.history.push('/inspections/');
  }

  _getInspectionData(inspectionId) {
    Service.get('Inspections', parseInt(inspectionId)).then((inspection) => {

      if ( !Array.isArray(inspection.defects) ) {
        const allDefects = Object.entries(inspection.defects);

        allDefects.sort(function(a, b) {
          if (parseFloat(a[1].defect.kms_start) > parseFloat(b[1].defect.kms_start))
            return 1;
          else if (parseFloat(a[1].defect.kms_start) < parseFloat(b[1].defect.kms_start))
            return -1;
          else
            return 0;
        });

        let sortedDefects = [];

        for (let i = 0; i < allDefects.length; ++i) {
          sortedDefects.push(allDefects[i][1]);
        }

        inspection.defects = sortedDefects;
      }

      const inspectionData = Promise.all([
        Service.getAllFromIndex('Tracks', 'siteIndex', inspection.site),
        Service.getAllFromIndex('WorkOrders', 'siteIndex', inspection.site)
      ]);

      inspectionData.then((values) => {
        for ( var key in values[0] ) {
          if ( !inspection.tracks.hasOwnProperty(values[0][key].id) )
            inspection.tracks[values[0][key].id] = {
              'id': values[0][key].id,
              'name': values[0][key].name,
              'defects': [],
              'defect_order_reverse': false,
              'inspected': []
            }
        }

        this.setState({ inspection: inspection, tracks: values[0], workorders: values[1] }); 

        Service.put('Inspections', inspection);     
      });
    });
  }

  _addDefect() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/0/defects/0/add');
  }

  _addInspectionNote() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/comment');
  }

  componentDidMount() {
    this._getInspectionData(this.props.match.params.inspectionId);
  }

  render() {
    let { inspection, tracks } = this.state
    const { classes } = this.props;

    if (!inspection) {
      return <CircularProgress />
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }

    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={3}>
            <PageTitle object_type="inspection" object_id={ 'IN-' + inspection.id } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
          </Grid>
          <Box my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={ classes.listTitle }>
                <Typography gutterBottom className={ classes.listTitleHeader } variant="h5" component="h2">Tracks</Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <InspectionTrackList inspection={ inspection } tracks={ tracks }/>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={ classes.listTitle }>
                <Typography gutterBottom className={ classes.listTitleHeader } variant="h5" component="h2">Work Orders</Typography>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  <WorkOrderList workorders={ this.state.workorders }/>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} mb={10}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={ classes.listTitle }>
                <Typography gutterBottom className={ classes.listTitleHeader } variant="h5" component="h2">Notes</Typography>
              </Grid>
              {
                inspection.notes.length ?
                inspection.notes.map((n, index) =>
                  <Grid item xs={12} key={ 'insp_' + inspection + '_note_' + index }>
                    <Card border="info" my="2rem">
                      <CardHeader                   
                        avatar={
                          <Avatar aria-label="Defect Note">
                            { n.created_by.split(' ')[0][0] + n.created_by.split(' ')[1][0] }
                          </Avatar>
                        }
                        title={ n.inspection_note.title }
                        subheader={ Helper.friendlyDate(n.inspection_note.created_on) }
                      />
                      <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                          { n.inspection_note.inspection_note }
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : 
                <Grid item xs={12}>
                  <Paper>
                    <List>
                      <ListItem color="textPrimary"><ListItemText primary='No Inspection Notes found.' /></ListItem>
                    </List>
                  </Paper>
                </Grid>
              }
            </Grid>
          </Box>
        </Container>
        <AppBar position="fixed" color="primary" className={ classes.appBar }> 
          <BottomNavigation
            showLabels
          >
            <BottomNavigationAction label="Back" icon={<AssignmentReturn />} onClick={ this._goToInspections } />
            <BottomNavigationAction label="Inspection Note" icon={<NoteAdd />} onClick={ this._addInspectionNote } />
            { 
              !this.state.inspection.inspection_status ?
                <BottomNavigationAction label="Certify" icon={<Beenhere />} onClick={ this._goToVerify } /> : ''
            }
          </BottomNavigation>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(InspectionTracks));