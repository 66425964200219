import React from 'react';
import { Box } from '@material-ui/core';
import RequireAuth from './RequireAuth';
import MenuDrawer from './MenuDrawer';
import Settings from './Settings';
import Home from './Home';
import Login from './Login';
import Inspections from './Inspections';
import InspectionTracks from './InspectionTracks';
import InspectionTrackDefects from './InspectionTrackDefects';
import InspectionNoteAdd from './InspectionNoteAdd';
import InspectionConfirmation from './InspectionConfirmation';
import InspectionVerified from './InspectionVerified';
import WorkOrders from './WorkOrders';
import WorkOrder from './WorkOrder';
import WorkOrderVerify from './WorkOrderVerify';
import WorkOrderPhotoAdd from './WorkOrderPhotoAdd';
import WorkSafetyConfirmation from './WorkSafetyConfirmation';
import Defect from './Defect';
import DefectAdd from './DefectAdd';
import DefectEdit from './DefectEdit';
import DefectNoteAdd from './DefectNoteAdd';
import DefectNoteEdit from './DefectNoteEdit';
import DefectPhotoAdd from './DefectPhotoAdd';
import DefectReassessment from './DefectReassessment';
import DefectTrackChangeRequest from './DefectTrackChangeRequest';
import UpdateSnackBar from "./UpdateSnackBar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

class App extends React.Component {

  counter;

  constructor(props) {
    super(props);
    
    this.counter = 0;
    
    this.state = {
      isLoading: true,
      isAuthenticated: localStorage.getItem("authenticated")
    };
  }

  render() {
    return (
      <React.Fragment>
        <UpdateSnackBar />
        <Router>
          <Box my={3}>
            <MenuDrawer />
            <Switch>
              <Route exact path='/'  render={() => {
                return (
                    (localStorage.getItem("authenticated") === 'true') ? 
                    <Home app_version = {this.props.version } /> :
                    <Login />
                  )
                }}
              />
              <Route exact path="/Login">
                <Login />
              </Route>
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/comment'} key={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/comment'}>
                <RequireAuth>
                  <DefectNoteAdd />
                </RequireAuth>
              </Route>  
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/reassess'} key={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/reassess'}>
                <RequireAuth>
                  <DefectReassessment />
                </RequireAuth>
              </Route>  
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/track-change'} key={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/track-change'}>
                <RequireAuth>
                  <DefectTrackChangeRequest />
                </RequireAuth>
              </Route>  
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/comment/:defectNoteId'} key={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/comment/:defectNoteId'}>
                <RequireAuth>
                  <DefectNoteEdit />
                </RequireAuth>
              </Route>  
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/addphoto'} key={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/addphoto'}>
                <RequireAuth>
                  <DefectPhotoAdd />
                </RequireAuth>
              </Route>
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/comment'} key={'/inspections/:inspectionId/tracks/:trackId/comment'}>
                <RequireAuth>
                  <InspectionNoteAdd />
                </RequireAuth>
              </Route>
              <Route exact path={'/inspections/:inspectionId/verify'} key={'/inspections/:inspectionId/verify'}>
                <RequireAuth>
                  <InspectionConfirmation />
                </RequireAuth>
              </Route>
              <Route exact path={'/inspections/:inspectionId/comment'} key={'/inspections/:inspectionId/comment'}>
                <RequireAuth>
                  <InspectionNoteAdd />
                </RequireAuth>
              </Route>
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects/0/add'} key={'/inspections/:inspectionId/tracks/:trackId/defects/0/add'}>
                <RequireAuth>
                  <DefectAdd />
                </RequireAuth>
              </Route>
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/edit'} key={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId/edit'}>
                <RequireAuth>
                  <DefectEdit />
                </RequireAuth>
              </Route>
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId'} key={'/inspections/:inspectionId/tracks/:trackId/defects/:defectId'}>
                <RequireAuth>
                  <Defect />
                </RequireAuth>
              </Route>
              <Route exact path={'/inspections/:inspectionId/tracks/:trackId/defects'} key={'/inspections/:inspectionId/tracks/:trackId/defects'}>
                <RequireAuth>
                  <InspectionTrackDefects />
                </RequireAuth>
              </Route>     
              <Route exact path={'/inspections/:inspectionId/tracks'} key={'/inspections/:inspectionId/tracks'}>
                <RequireAuth>
                  <InspectionTracks />
                </RequireAuth>
              </Route>
              <Route path='/inspections/:inspectionId/verified' key='/inspections/:inspectionId/verified'>
                <RequireAuth>
                  <InspectionVerified />
                </RequireAuth>
              </Route>
              <Route path='/inspections/:inspectionId/worksafety' key='/inspections/:inspectionId/worksafety'>
                <RequireAuth>
                  <WorkSafetyConfirmation />
                </RequireAuth>
              </Route>
              <Route exact path="/inspections" key="/inspections">
                <RequireAuth>
                  <Inspections />
                </RequireAuth>
              </Route>
              <Route exact path={'/workorders/:workOrderId/addphoto'} key={'/workorders/:workOrderId/addphoto'}>
                <RequireAuth>
                  <WorkOrderPhotoAdd />
                </RequireAuth>
              </Route>
              <Route exact path={'/workorders/:workOrderId/verify'} key={'/workorders/:workOrderId/verify'}>
                <RequireAuth>
                  <WorkOrderVerify />
                </RequireAuth>
              </Route>
              <Route exact path={'/workorders/:workOrderId'} key={'/workorders/:workOrderId'}>
                <RequireAuth>
                  <WorkOrder />
                </RequireAuth>
              </Route>
              <Route exact path='/workorders' key='/workorders'>
                <RequireAuth>
                  <WorkOrders />
                </RequireAuth>
              </Route>
              <Route exact path='/settings' key='/settings'>
                <RequireAuth>
                  <Settings />
                </RequireAuth>
              </Route>
              <Route path="*" key="no-match" render={() => {
                return (
                    <Redirect to="/" />
                  )
                }}
              />         
            </Switch>
          </Box>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;