import React from "react";
import { Grid, Typography } from '@material-ui/core';
import { ErrorOutline, Assignment, TrainRounded } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import clsx from  'clsx';


const useStyles = theme => ({
  root: {
    display: 'inline-flex',
    gap: '6px'
  },
  pageContainer: {
    'padding-top': 0,
    'padding-bottom': theme.spacing(1),
    'background-color': 'transparent',
    'margin-bottom': theme.spacing(1)
  },
  title: {
    'vertical-align': 'middle',
    'align-items': 'center',
    'padding-bottom': 0,
    'margin-bottom': 0,
    'font-size': '1.4rem'
  },
  subtitle: {
    'vertical-align': 'top',
    'align-items': 'baseline',
  },
  object: {
    color: '#999'
  },
  icon: {
    color: theme.palette.primary.main
  },
  divider: {
    color: theme.palette.secondary.main,
    'font-size': '1.5rem'
  },
  page_action: {
    color: theme.palette.secondary.main,
    'text-transform': 'capitalize'
  }
});

class PageSubtitle extends React.Component {

  render() {

    const { classes } = this.props;
    var my_icon = ''

    switch ( this.props.object_type ) {
      case 'defect':
        my_icon = <ErrorOutline className={ classes.icon } />;
        break;
      case 'track':
        my_icon = <TrainRounded className={ classes.icon } />;
        break;
        case 'work_order':
          my_icon = <Assignment className={ classes.icon } />;
          break;
      default:

    }

    return (
      <Grid item xs={ this.props.col_width ? this.props.col_width : 12 }>
        <Typography variant="h5" component="h3" gutterBottom className={ clsx(classes.root, classes.title) }>
          { my_icon } <span className={ classes.object }>{ this.props.object_id }</span> <span className={ classes.divider }>|</span> <span className={ classes.page_action }>{ this.props.page_action }</span>
        </Typography>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(PageSubtitle);