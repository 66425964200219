import React from "react";
import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, CircularProgress } from '@material-ui/core';
import { Train, CheckCircle, PlayCircleOutline } from '@material-ui/icons';
import { Link, withRouter } from 'react-router-dom';

class InspectionTrackList extends React.Component {

  generateURI(inspection_id, track_id) {
    return "/inspections/" + inspection_id + "/tracks/" + track_id + '/defects';
  }

  inspectableDefects(track, defects) {
    var inspectable = 0;
    for (var i in defects) {
      if ((defects[i].area === parseInt(track)) && ( defects[i].defect.short_id !== 'PENDING'))
        inspectable++;
    }

    return inspectable;
  }

  render() {
    if (!this.props.inspection) {
      return <CircularProgress />;
    }

    return (
      <List>
        {Object.keys(this.props.tracks).map(key => {
          let track = null;
          let inspectedDefects = 0;
          let inspectableDefectCount = 0;

          if ( this.props.inspection.tracks.hasOwnProperty(this.props.tracks[key].id) ) {
            track = this.props.inspection.tracks[this.props.tracks[key].id];

            if ( track.inspected )
              inspectedDefects = track.inspected.length;

            inspectableDefectCount = this.inspectableDefects(track.id, this.props.inspection.defects );
          } else {
            track = this.props.tracks[key];
          }

          return (
            <ListItem key={ 'track_' + track.id } component={Link} to={this.generateURI(this.props.match.params.inspectionId, track.id)}>
              <ListItemIcon>
                <Train />
              </ListItemIcon>
              <ListItemText
                primary={track.name}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="go to">                   
                {
                  (( inspectableDefectCount === inspectedDefects ) && ( inspectableDefectCount !== 0 )) ? 
                    <CheckCircle color="primary" /> : <PlayCircleOutline />
                }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default withRouter(InspectionTrackList);