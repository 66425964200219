import React from "react";
import { withStyles } from "@material-ui/core/styles";

const useStyles = (theme) => ({
  prioritySymbol: ({
    'width': '64px',
    'height': '64px',
    'background-color': (props) => props.colour,
    'border-radius': '1000px',
    'color': '#fff',
    'font-size': '32px',
    'font-weight': 'bold',
    'line-height': '64px',
    'text-align': 'center'
  })
});

class PrioritySymbol extends React.Component {

  render() {
    const { classes } = this.props;

    return ( <div className={ classes.prioritySymbol }>{ this.props.name }</div> );
  }
}

export default withStyles(useStyles)(PrioritySymbol);