import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#72bf44" 
    },
    secondary: {
      main: "#1a383b"
    },
    danger: {
      main: "#dc3545"
    },
    background: {
      default: "#efefef"
    }
  }
});

const app_version = serviceWorkerRegistration.version();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={ theme }>
      <CssBaseline>
        <App version={ app_version }/>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ 
  onUpdate: (registration) => {
    let refreshBar = document.querySelector('#custom-refresh-bar');
    if(refreshBar) {
      refreshBar.style.display = '';
    }
  }
});

document.onvisibilitychange = (e) => {
  let refreshBar = document.querySelector('#custom-refresh-bar');
  if(refreshBar && !document.hidden) {
    serviceWorkerRegistration.forceSWupdate();
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
