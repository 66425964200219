import React from 'react';
import { AppBar, Toolbar, Drawer, IconButton, List, Divider, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { Search, Assignment, Settings, Home, Menu } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";

const useStyles = theme => (
  (window.location.hostname === 'app.lycomms.com') ? {
    appBar: {
      top: 0
    },
    appBarSpacer: theme.mixins.toolbar,
    menuButton: {
      marginLeft: theme.spacing(2),
    },
    title: {
      flexGrow: 1
    }
  } : {
    appBar: {
      top: 0,
      'background-color': 'red'
    },
    appBarSpacer: theme.mixins.toolbar,
    menuButton: {
      marginLeft: theme.spacing(2),
    },
    title: {
      flexGrow: 1
    }
  }
);

class MenuDrawer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      left: false
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  toggleDrawer(event) {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ left: !this.state.left });
  };

  renderMenuItems() {
    return (
      <div
        role="presentation"
        onClick={ this.toggleDrawer }
        onKeyDown={ this.toggleDrawer }
      >
        <List>
          <ListItem button component="a" href="/" key="Home">
            <ListItemIcon><Home /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component="a" href="/inspections/" key="Inspections">
            <ListItemIcon><Search /></ListItemIcon>
            <ListItemText primary="Inspections" />
          </ListItem>
          <ListItem button component="a" href="/workorders/" key="WorkOrders">
            <ListItemIcon><Assignment /></ListItemIcon>
            <ListItemText primary="Work Orders" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component="a" href="/settings/" key="Settings">
            <ListItemIcon><Settings /></ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div>        
        <React.Fragment key="left">
          <AppBar position="fixed" color="primary" aria-label="menu" className={ classes.appBar }>
            <Toolbar>            
              <Typography variant="h6" className={classes.title}>
                Lycopodium
              </Typography>
              <IconButton color="secondary" edge="end" className={ classes.menuButton } onClick={ this.toggleDrawer } aria-label="menu"><Menu /></IconButton>  
            </Toolbar>
          </AppBar>
          <div className={classes.appBarSpacer} />
          <Drawer anchor={"left"} open={this.state.left} onClose={ this.toggleDrawer }>
            { this.renderMenuItems() }
          </Drawer>
        </React.Fragment>
      </div>
    );
  }
}

export default withStyles(useStyles)(MenuDrawer);