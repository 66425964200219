class Validator {
  
  requireText(name, value) {
    if ( value !== null )
      return value.toString().trim().length > 0;
    else
      return false;
  }  

  validateNumber(name, value) {
    if ( value !== null )
      return !isNaN(parseFloat(value.toString().trim()));
    else
      return false;
  }  

  validateAccessToken(name, value) {
    if ( value !== null )
      return /[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}/.test(value);
    else
      return false;
  }

  validateDate(name, value) {
    if ( value !== null)
      if ( value instanceof Date && !isNaN(value) )
        return true; 
      else
        return false;
    else
      return true;
  }  

  requireTextArea(name, value) {
    if ( value !== null )
      return value.toString().trim().length > 3;
    else
      return false;
  }
  
  requireSelect(name, value) {
    if ( value !== null )
      return (value.toString().trim().length > 0) && ( parseInt(value.toString().trim()) !== 0 );
    else
      return false;
  }

  validateDefectRepairDueDate(repair_date, priority, action = 'edit') {

    const repairDate = new Date(repair_date);
    const todayDate = new Date();

    repairDate.setHours(0,0,0,0);
    todayDate.setHours(0,0,0,0);
    
    const day = 1000 * 60 * 60 * 24;
    const week = 7 * day;
    const month = 28 * day;
    const year = 365 * day;

    let dateDiff = repairDate.getTime() - todayDate.getTime();

    let validate_status = false;
    let error_msg = '';
    
    if ((priority !== 5 /* P4 */) && (priority !== 6 /* M */) && (repair_date === null))
      error_msg = "Repair Due Date cannot be empty when priority is P3 or higher.";
    else if ((action === 'add') && (priority !== 5 /* P4 */) && (priority !== 6 /* M */) && (repair_date !== null) && (dateDiff < 0))
      error_msg = "Repair Due Date must be TODAY or later.";
    else if ((priority === 1 /* E */) && (dateDiff/day > 1)) 
      error_msg = "Repair Due Date must be within range +1 day from today when priority is E.";
    else if ((priority === 2 /* P1 */) && (dateDiff/week > 1))
      error_msg = "Repair Due Date must be within range +1 week from today when priority is P1.";
    else if ((priority === 3 /* P2 */) && (dateDiff/month > 1)) 
      error_msg = "Repair Due Date must be within range +1 month from today when priority is P2.";
    else if ((priority === 4 /* P3 */) && (dateDiff/year > 1))
      error_msg = "Repair Due Date must be within range +1 year from today when priority is P3.";
    else
      validate_status = true;

    return { 'status': validate_status, 'msg': error_msg };
  }

  validateForm(formFields, fieldData, validator) {

    let errors = {
      count: 0,
      msgs: {}
    };

    const result = formFields.map((formField) => {
      return validator(formField, fieldData[formField]);
    });
    
    for ( var i = 0;i < formFields.length;++i ) {
      errors.msgs[formFields[i]] = result[i][formFields[i]];
      if ( result[i][formFields[i]] !== '' )
        errors.count++;
    }
    
    return errors;
  }
}

export const FormValidation = new Validator()