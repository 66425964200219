import React from "react";
import { Container, Grid, Typography, MenuItem, TextField, Button, CircularProgress } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import { FormValidation } from "./services/FormValidation";
import PageTitle from './PageTitle'

class InspectionNoteAdd extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      inspectionId: this.props.match.params.inspectionId,
      inpsection: {},
      note_title: 'General Inspection Note',
      asset_class: 0,
      asset_classes: [],
      inspection_note: '',
      username: '',
      formFields: [ 'inspection_note' ],
      formErrors: {}
    };

    this._getData = this._getData.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._validateInput = this._validateInput.bind(this);

    /*Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      this.state.inspection = inspection;
    });*/
    
    Service.getAll('User').then((user) => { 
      //this.setState({ username: user[0].first_name + ' ' + user[0].last_name });
      this.state.username = user[0].first_name + ' ' + user[0].last_name;
    });
  }

  _getData() {

    Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      
      const getDropdownData = Promise.all([
        Service.getAllFromIndex('Assets', 'siteIndex', 0),
        Service.getAllFromIndex('Assets', 'siteIndex', parseInt(inspection.site))        
      ]);

      getDropdownData.then((values) => {
        this.setState({
          inspection: inspection,
          asset_classes: values[0].concat(values[1])
        });
      });
    });
  }

  componentDidMount() {
    this._getData();
  }

  _validateInput(name, value) {
    //console.log('checking => ' + name + ':' + value);
    let errors = {};
    var testResult = false;

    switch ( name ) {
      case 'inspection_note':
        testResult = FormValidation.requireTextArea(name, value);
        errors[name] = testResult ? "" : "Comments cannot be empty.";
        break;
      default:
          
    }

    return errors;
  }

  _handleChange(e) {
    const { name, value } = e.target;  
    let error = this._validateInput(name, value);
      
    this.setState({ [name]: value, formErrors: { ...this.state.formErrors, [name]: error[name] } });
  }

  _handleCancel() {
    if( this.props.match.params.trackId !== 'undefined')
      this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/');
    else
      this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/');
  }

  _handleSave() {
    let errors = FormValidation.validateForm(this.state.formFields, this.state, this._validateInput);
    
    if( errors.count === 0 ) {
      let inspection = this.state.inspection;
      var asset_class = parseInt(this.state.asset_class) !== 0 ? this.state.asset_class : null;
      const timestamp_str =  ' -- ' + new Date().toString();
      const inspectionNoteUuid = Helper.generateUUID();

      inspection.notes = [{        
        'uuid': inspectionNoteUuid,
        'created_by': this.state.username,
        'inspection_note': {
          'created_on': new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
          'title': 'General Inspection Note',
          'asset_class': asset_class,
          'inspection_note': this.state.inspection_note + timestamp_str
        }
      }].concat(inspection.notes);

      Service.put('Inspections', inspection);

      Service.put('Changelog', {
        uuid: inspectionNoteUuid,
        change_type: 'newinspectionnote',
        action_datetime: new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
        inspection: this.props.match.params.inspectionId,
        title: 'General Inspection Note',
        asset_class: asset_class,
        inspection_note: this.state.inspection_note + timestamp_str
      }).then((status) => {
        //console.log(status);

        if( this.props.match.params.trackId !== 'undefined')
          this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects');
        else
          this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/');
      });
    } else {
      this.setState({ formErrors: { ...errors.msgs }});
      alert('This form has errors.');
    }
  }

  render() {
    
    let { inspection, asset_classes } = this.state;

    if (!inspection) {
      return <CircularProgress />
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }

    return (
      <Container spacing={3}>
        <Grid container spacing={3}>
          <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h3" gutterBottom>New Inspection Note</Typography>
            </Grid>
            <form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  select
                  labelid="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="asset_class"
                  value={ parseInt(this.state.asset_class) }
                  onChange={ this._handleChange }
                  label="Asset Class"
                  {...(this.state.formErrors["asset_class"] && { error: true, helperText: this.state.formErrors["asset_class"] })}
                >
                  <MenuItem value="0">
                    <em>None</em>
                  </MenuItem>
                  {
                    asset_classes.map(i => <MenuItem value={ i.id } key={ "asset-" + i.id }>{ i.name }</MenuItem>)
                  }
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="outlined-textarea"
                  label="Comments"
                  variant="outlined"
                  margin="normal"
                  required
                  multiline
                  minRows={8}
                  name="inspection_note"
                  value={ this.state.inspection_note }
                  onChange={ this._handleChange }
                  fullWidth
                  {...(this.state.formErrors["inspection_note"] && { error: true, helperText: this.state.formErrors["inspection_note"] })}
                />
              </Grid>
            </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._handleSave }>Save</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(InspectionNoteAdd);