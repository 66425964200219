import React from "react";
import { Box, Container, Grid, Typography, CircularProgress, AppBar, BottomNavigation, BottomNavigationAction, Card, CardHeader, CardContent, Avatar, List, ListItem, Paper } from '@material-ui/core';
import { Today, CameraAlt, AssignmentReturn, Beenhere } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from "@material-ui/lab";
import { withRouter } from "react-router";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import PageTitle from "./PageTitle";

const useStyles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  hasIcon: {
    'vertical-align': 'middle',
    display: 'inline-flex',
    'align-items': 'center',
    gap: '6px'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
  workOrderInspected: {
    color: 'green'
  }
});

class WorkOrder extends React.Component {

  photoIndex = 0;
  
  constructor(props) {
    super(props)
    this.state = {    
      speedDialState: false,  
      workOrderPhotos: []
    }

    this._getWorkOrder = this._getWorkOrder.bind(this);
    this._handleAddPhoto = this._handleAddPhoto.bind(this);
    this._goToVerify = this._goToVerify.bind(this);
    this._goToWorkOrders = this._goToWorkOrders.bind(this);
    this._toggleSpeedDial = this._toggleSpeedDial.bind(this);
    
    this.hiddenFileInput = React.createRef();
    this.photoGallery = React.createRef();
  }

  _getWorkOrder(workOrderId) {
    Service.get('WorkOrders', parseInt(workOrderId)).then((workorder) => {
      this.setState({ workorder: workorder, isLoading: false })
    });
  }

  _goToWorkOrders() {
    this.props.history.push('/workorders/');
  }

  _handleAddPhoto() {
    this.props.history.push('/workorders/' + this.props.match.params.workOrderId + '/addphoto');
  }

  componentDidMount() {
    this._getWorkOrder(this.props.match.params.workOrderId);
  }

  _goToVerify() {
    this.props.history.push('/workorders/' + this.props.match.params.workOrderId + '/verify');
  }

  renderImage(imageSrc) {
    this.photoIndex = this.photoIndex + 1;

    var figureSrc = '';
    
    if(imageSrc.indexOf(';base64') >= 0)
      figureSrc = imageSrc;
    else {
      if (window.location.hostname === 'app.lycomms.com')
        figureSrc = 'https://www.lycomms.com/download/db/' + imageSrc;
      else if (window.location.hostname === 'app-test.lycomms.com')
        figureSrc = 'https://test.lycomms.com/download/db/' + imageSrc;
      else if (window.location.hostname === 'app-staging.lycomms.com')
        figureSrc = 'https://staging.lycomms.com/download/db/' + imageSrc;
      else
        figureSrc = 'https://www.lycomms.com/download/db/' + imageSrc;
    }

    return (
      <React.Fragment key={this.photoIndex}>
        <img
          width={window.innerWidth / 3.5}
          src={ figureSrc }
          alt=""
        />
      </React.Fragment>
    )
  }

  _toggleSpeedDial() {
    this.setState({ speedDialState: !this.state.speedDialState });
  }

  render() {
    let { workorder } = this.state
    const { classes } = this.props;
    //console.log(workorder);

    if (!workorder) {
      return <CircularProgress />
    }

    let plannedDate = new Date(workorder.signoff_datedue);
    
    return (
      <React.Fragment>
        <Container>
          <Box>
            <Grid container spacing={3}>
              <PageTitle object_type="work_order" object_id={ 'WO-' + workorder.id } page_action={ workorder.name } />
              <Grid item xs={6}>
                <Typography variant="h6" component="h3" gutterBottom className={ classes.hasIcon }><Today color="primary" /> { workorder ? plannedDate.getDate().toString().padStart(2, '0') + '/' + (plannedDate.getMonth() + 1).toString().padStart(2, '0') + '/' + plannedDate.getFullYear() : '' }</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="h6" component="h4">Work Type</Typography>
                <Typography variant="body2" color="textSecondary" component="p">{ workorder.work_type }</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6" component="h4">Primary Activity</Typography>
                <Typography variant="body2" color="textSecondary" component="p">{ workorder.primary_activity }</Typography>
              </Grid>
              {
                (workorder.secondary_activity !== '') ?
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h6" component="h4">Secondary Activity</Typography>
                  <Typography variant="body2" color="textSecondary" component="p">{ workorder.secondary_activity }</Typography>
                </Grid> : ''
              }
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6" component="h4">Work Summary</Typography>
                <Typography variant="body2" color="textSecondary" component="p">{ workorder.work_summary }</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6" component="h4">Works as Executed</Typography>
                <Typography variant="body2" color="textSecondary" component="p">{ workorder.works_as_executed ? workorder.works_as_executed : 'Not verified'}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} mb={ !this.state.workorder.verify ? 10 : '' }>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="h2">Photos ({ this.state.workorder.images.length })</Typography>
              </Grid>
              <Grid item xs={12}>
              {
                this.state.workorder.images.length ?                       
                  <Grid container spacing={3} ref={ this.photoGallery }>
                  {
                    this.state.workorder.images.map((i) => this.renderImage(i.image_file)) 
                  }
                  </Grid> : 
                  <Paper>
                    <List>
                      <ListItem color="textPrimary">No Photos</ListItem>
                    </List>
                  </Paper>
              }
              </Grid>
            </Grid>
          </Box>
          {
            this.state.workorder.verify ? 
            <Box mt={3} mb={10}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5" component="h2">Inspected</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Card border="info" my="2rem">
                    <CardHeader                   
                      avatar={
                        <Avatar aria-label="Defect Note">
                          R
                        </Avatar>
                      }
                      title={ this.state.workorder.verify.recommendation }
                      subheader={ Helper.friendlyDate(this.state.workorder.verify.created_on) }
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                        { this.state.workorder.verify.wo_comment }
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box> : ''
          }
          <div>
            <SpeedDial
              ariaLabel="speed dial"
              className={ classes.fab }
              icon={ <SpeedDialIcon /> }
              onClose={ this._toggleSpeedDial }
              onOpen={ this._toggleSpeedDial }
              open={ this.state.speedDialState }
              direction="up"
            >
              <SpeedDialAction
                key='Add Photo'
                icon={ <CameraAlt /> }
                tooltipTitle='Add Photo'
                onClick={ this._handleAddPhoto }
              />
            </SpeedDial>
          </div>
        </Container>
        <AppBar position="fixed" color="primary" className={ classes.appBar }> 
          <BottomNavigation
            showLabels
          >
            <BottomNavigationAction label="Work Orders" icon={<AssignmentReturn />} onClick={ this._goToWorkOrders } />
            <BottomNavigationAction label={ this.state.workorder.inspection_status ? "Verified" : 'Verify' } icon={<Beenhere />} onClick={ this._goToVerify }  disabled={ this.state.workorder.inspection_status ? true : false } className={ this.state.workorder.inspection_status ? classes.workOrderInspected : '' }/>
          </BottomNavigation>
        </AppBar>
      </React.Fragment>  
    );
  }
}

export default withRouter(withStyles(useStyles)(WorkOrder));