import React from "react";
import { Box, Container, Grid, Paper, List, ListItem, Typography, CircularProgress, Card, CardHeader, Avatar, CardContent, AppBar, BottomNavigation, BottomNavigationAction, Button, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { Today, Edit, SkipPrevious, SkipNext, Train, Beenhere, MyLocationOutlined, CheckCircleOutline, RadioButtonCheckedOutlined, ErrorOutline, Add, WarningRounded } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import { FormValidation } from "./services/FormValidation";
import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";
import PrioritySymbol from "./PrioritySymbol";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from  'clsx';

const useStyles = theme => ({
  containerWithAppBar: {
    'margin-bottom': theme.spacing(12)
  },
  contentHeaderContainer: {
    'align-items': 'center'
  },
  fullWidth: {
    width: '100%'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  '.MuiButton-root .MuiSvgIcon-root': {
    color: 'green'
  },
  tertiaryButton: {
    color: '#fff',
    'background-color': '#009966'
  },
  pageNotification: {
    'background-color': '#f89406',
    padding: theme.spacing(2),
    'border-radius': '5px'
  },
  pageNotificationText: {
    color: '#193441',
    'font-size': '1rem'
  },
  sectionIconButton: {
    padding: '0px!important'
  },
  paperGrid: {
    padding: '1rem',
    'margin-top': '1.5rem',
    'margin-bottom': '2.5rem'
  },
  headerRow: {
    'padding-bottom': '0px'
  },
  hasIcon: {
    'vertical-align': 'middle',
    display: 'flex',
    'align-items': 'center',
    gap: '6px'
  },
  alignRight: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
  detailsHeader: {
    'font-size': '1em',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.54)' 
  },
  detailsContent: {
    'font-size': '1.25em',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.65)'
  },
  detailsContentFine: {
    'font-size': '0.75rem',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.65)'
  },
  cardWrapper: {
    'background-color': '#f6f6f6'
  },
  noteHeader: {
    'padding-bottom': '0px',
  },
  noteAvatar: {
    'background-color': '#009966',
    color: '#fff'
  },
  addActionButton: {
    color: '#1a383b',
    'background-color': 'transparent',
    'border-radius': '1000px',
    border: '2px solid #72bf44',
    width: '40px',
    height: '40px',
    padding: '8px'
  },
  noteActionButton: {
    color: '#fff',
    'background-color': '#1a383b',
    'border-radius': '1000px',
    width: '40px',
    height: '40px',
    padding: '8px'
  },
  disabledActionButton: {
    color: 'rgba(0,0,0,0.12)',
    'background-color': 'transparent',
    'border-radius': '1000px',
    border: '2px solid rgba(0,0,0,0.12)',
    width: '40px',
    height: '40px',
    padding: '8px'
  },
  noteContentTypography: {
    'font-size': '1rem',
  },
  colorGreen: {
    color: 'green!important'
  },
  colorRed: {
    color: 'red'
  },
  colorGrey: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  accordionSummary: {
    color: '#fff',
    'background-color': '#1a383b',
    'border-radius': '5px',
    padding: '12px',
    '&.Mui-expanded': {
      'border-bottom-left-radius': '0!important',
      'border-bottom-right-radius': '0!important'
    },
    '& > .MuiIconButton-root': {
      color: '#fff'
    }
  },
  accordionDetails: {
    'padding-top': theme.spacing(3)
  },
  photoGridContainer: {
    '& img': {
      'border-radius': '5px'
    }
  }
});

class Defect extends React.Component {

  photoIndex = 0;

  constructor(props) {
    super(props);

    this.state = {
      inpsection: {},
      defect: null,
      defectReassessCount: 0,
      defectPhotos: [],
      priorities: [],
      priority: '',
      status: '',
      asset_class: '',
      track: '',
      username: ''
    };

    this._getDefect = this._getDefect.bind(this);
    this._goToTrack = this._goToTrack.bind(this);
    this._goToInspection = this._goToInspection.bind(this);
    this._goToDefectEdit = this._goToDefectEdit.bind(this);
    this._addDefect = this._addDefect.bind(this);
    this._inspectDefect = this._inspectDefect.bind(this);
    this._reassessDefect = this._reassessDefect.bind(this);
    this._skipDefect = this._skipDefect.bind(this);
    this._skipDefectPrev = this._skipDefectPrev.bind(this);
    this._skipDefectNext = this._skipDefectNext.bind(this);
    this._handleAddPhoto = this._handleAddPhoto.bind(this);
    this._goToDefectNoteAdd = this._goToDefectNoteAdd.bind(this);
    this._nextDefectIdx = this._nextDefectIdx.bind(this);
    
    this.photoGallery = React.createRef();
    
    Service.getAll('User').then((user) => { 
      //this.setState({ username: user[0].first_name + ' ' + user[0].last_name });
      this.state.username = user[0].first_name + ' ' + user[0].last_name;
    });
  }

  _getDefect(inspectionId, defectId) {
    
    Service.get('Inspections', parseInt(inspectionId)).then((inspection) => {
      const filteredDefects = inspection.defects.filter((defect) => defect.defect.id.toString() === defectId);
      const defect = filteredDefects[0];

      var defectReassessCount = 0;

      defect.defect_notes.forEach(function(note) {
        if ( note.defect_note.defect_note.includes('Inspected - Reassessed') )
          defectReassessCount++;
      });

      const variableLookups = Promise.all([        
        Service.get('Priorities', defect.defect.priority),
        Service.get('Statuses', defect.defect.defect_status),
        Service.get('Assets', defect.defect.asset_class),
        Service.get('Tracks', parseInt(defect.defect.area)),
      ]);

      variableLookups.then((values) => {
        this.setState({
          inspection: inspection, 
          defect: defect, 
          defectReassessCount: defectReassessCount, 
          defectPhotos: defect.defect_images, 
          priority: values[0].name, 
          pcolor: values[0].colour,
          status: values[1].name, 
          asset_class: values[2].name, 
          track: values[3].name 
        });
      });
    });
  }

  _goToTrack() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects');
  }

  _goToInspection() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/');
  }

  _handleAddPhoto() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId + '/addphoto');
  }

  _addDefect() {
    let nextDefectId = this._nextDefectIdx();

    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/0/add/?next=' + nextDefectId);
  }

  _goToDefectNoteAdd() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId + '/comment');
  }

  _goToDefectNoteEdit(defectNoteId) {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId + '/comment/' + defectNoteId);
  }

  _goToDefectEdit() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId + '/edit');
  }

  _nextDefectIdx(step = 1) {    
    let nextDefectIdx = this.state.inspection.tracks[this.props.match.params.trackId].defect_order.findIndex((defect) => defect.toString() === this.props.match.params.defectId) + step;

    if ( (nextDefectIdx !== -1) && ( this.state.inspection.tracks[this.props.match.params.trackId].defect_order[nextDefectIdx] ) )
      return this.state.inspection.tracks[this.props.match.params.trackId].defect_order[nextDefectIdx];
    else
      return null; // No more defects
  }

  _inspectDefect(inspect_type) {

    let inspection = this.state.inspection;
    const defectIdx = inspection.defects.findIndex((defect) => defect.defect.id.toString() === this.props.match.params.defectId);

    let defect = this.state.defect;

    if (!defect.inspection_status) {
      let dbChanges = [];

      let today = new Date();
      let repair_date = new Date(defect.defect.repair_due_date);

      today.setHours(0,0,0,0);
      repair_date.setHours(0,0,0,0);

      let inspect_msg = (inspect_type === 'nochange') ? 'Inspected - No Change' : 'Inspected - Updated';

      // Do not update defect_status to Overdue if M or P4 or if Repair Date not set
      let test_overdue_state = ( defect.defect.priority !== 6 /* M */) && ( defect.defect.priority !== 5 /* P4 */) && ( defect.defect.repair_due_date !== null ) && ( repair_date < today )

      if (( test_overdue_state ) && ( defect.defect.defect_status !== 3 /* Invalid */) && ( defect.defect.defect_status !== 5 /* Overdue */) && ( defect.defect.defect_status !== 6 /* Verified Complete */)) {
        defect.defect.defect_status = 5 /* Overdue */;

        dbChanges.concat(
          Service.put('Changelog', {
            uuid: Helper.generateUUID(),
            change_type: 'defect_update',
            action_datetime: Helper.generateActionDateTime(),
            inspection: this.props.match.params.inspectionId,
            defect: this.props.match.params.defectId,
            item: 'defect_status',
            value: defect.defect.defect_status
          })
        );
      }

      let uuid = Helper.generateUUID();
      const timestamp_str =  ' -- ' + new Date().toString();

      defect.inspection_status = uuid;
      defect.defect_notes = [{
        'uuid': uuid,
        'created_by': this.state.username,
        'defect_note': {
          'created_on': Helper.generateActionDateTime(),
          'defect_note_title': 'Inspected',
          'defect_note': inspect_msg
        }
      }].concat(defect.defect_notes);

      this.setState({ defect: defect });

      inspection.defects[defectIdx] = defect;
      inspection.tracks[this.props.match.params.trackId].inspected.push(this.props.match.params.defectId);

      dbChanges.concat(
        Service.put('Inspections', inspection)
      );
      
      dbChanges.concat(
        Service.put('Changelog', {
          uuid: uuid,
          change_type: 'newdefectnote',
          action_datetime: Helper.generateActionDateTime(),
          inspection: this.props.match.params.inspectionId,
          defect: this.props.match.params.defectId,
          defect_note_title: 'Inspected',
          defect_note: inspect_msg + timestamp_str
        })
      );

      const defectChanges = Promise.all(dbChanges);

      defectChanges.then((status) => {

        let nextDefectId = this._nextDefectIdx();

        if ( nextDefectId !== null)
          this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + nextDefectId );
        else 
          this._goToInspection();
      });
    } else {
      alert('The Defect has already been inspected.');
    }
  }

  _reassessDefect() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId + '/reassess');
  }

  _skipDefectPrev() {
    this._skipDefect('prev');
  }

  _skipDefectNext() {
    this._skipDefect('next');
  }

  _skipDefect(direction = 'next') {

    var nextDefectId = null;

    if ( direction === 'prev' )
      nextDefectId = this._nextDefectIdx(-1);
    else
      nextDefectId = this._nextDefectIdx();

    if ( nextDefectId !== null )
      this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + nextDefectId );
    else 
      this._goToInspection();
  }

  componentDidMount() {
    this._getDefect(this.props.match.params.inspectionId, this.props.match.params.defectId);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.defectId !== prevProps.match.params.defectId) {
      window['scrollTo']({ top: 0, behavior: 'smooth' });
      this._getDefect(this.props.match.params.inspectionId, this.props.match.params.defectId);
    }
  }

  renderImage(defectId, imageSrc) {
    this.photoIndex = this.photoIndex + 1;

    var figureSrc = '';
    
    if(imageSrc.indexOf(';base64') >= 0)
      figureSrc = imageSrc;
    else {
      if (window.location.hostname === 'app.lycomms.com')
        figureSrc = 'https://www.lycomms.com/download/db/' + imageSrc;
      else if (window.location.hostname === 'app-test.lycomms.com')
        figureSrc = 'https://test.lycomms.com/download/db/' + imageSrc;
      else if (window.location.hostname === 'app-staging.lycomms.com')
        figureSrc = 'https://staging.lycomms.com/download/db/' + imageSrc;
      else
        figureSrc = 'https://www.lycomms.com/download/db/' + imageSrc;
    }

    return (
      <Grid item xs={6} key={ 'defect-' + defectId + 'img-' + this.photoIndex }>
        <img
          width='100%'
          src={ figureSrc }
          alt=""
        />
      </Grid>
    )
  }

  renderDefectInspectButtons(classes, defect) {

    if ((('inspection_status' in defect) && ((defect.inspection_status.length > 0) || (defect.inspection_status === true))) || (('reassessed_status' in defect) && (defect.reassessed_status !== false))) {
      return (
        <Grid item xs={12}>
          <Button variant="contained" color="primary" className={ clsx(classes.fullWidth, classes.colorGreen) } size="large" disabled><CheckCircleOutline />&nbsp; { (('reassessed_status' in defect) && (defect.reassessed_status !== false)) ? 'Defect was Reassessed' : 'Defect was Inspected' }</Button>
        </Grid>
      )
    } else if (defect.defect.defect_status === 1) {
      return
    } else if ((defect.defect.priority === 5 /* P4 */) || (defect.defect.priority === 6 /* Monitor */)) {
      return (
        <Grid container spacing={3}>
          {
            (!(defect.hasOwnProperty('defect_changed')) || (defect.defect_changed === false)) ?
              <Grid item xs={12}>
                <Button variant="contained" color="primary" className={ classes.fullWidth } size="large" onClick={ () => this._inspectDefect('nochange') }><Beenhere />&nbsp; Inspected with No Change</Button>
              </Grid> : 
              <Grid item xs={12}>
                <Button variant="contained" className={ clsx( classes.fullWidth, classes.tertiaryButton ) } size="large" onClick={ () => this._inspectDefect('manual') }><Beenhere />&nbsp; Inspected with Updates</Button>
              </Grid>
          }
        </Grid>
      )
    } else {
      return (   
        <Grid container spacing={3}> 
          {
            (!(defect.hasOwnProperty('defect_changed')) || (defect.defect_changed === false)) ?
              <Grid item xs={12}>
                <Button variant="contained" color="primary" className={ classes.fullWidth } size="large" onClick={ () => this._inspectDefect('nochange') }><Beenhere />&nbsp; Inspected with No Change</Button>
              </Grid> : 
              <Grid item xs={12}>
                <Button variant="contained" className={ clsx( classes.fullWidth, classes.tertiaryButton ) } size="large" onClick={ () => this._inspectDefect('manual') }><Beenhere />&nbsp; Inspected with Updates</Button>
              </Grid>
          }
          {
            (!(defect.hasOwnProperty('manually_reassessed')) || (defect.manually_reassessed === false)) ?
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" className={ classes.fullWidth } size="large" onClick={ this._reassessDefect }><CheckCircleOutline />&nbsp; Reassess this Defect</Button>
            </Grid> : ''
          }
        </Grid>
      )
    }
  }

  render() {

    let { inspection, defect, defectReassessCount } = this.state
    const { classes } = this.props;

    if (!defect) {
      return <CircularProgress />;
    }

    let reportedDate = defect.defect.date_reported ? new Date(defect.defect.date_reported) : null;
    let repairDate = defect.defect.repair_due_date ? new Date(defect.defect.repair_due_date) : null;
    let repairDateOverdueStatus = FormValidation.validateDefectRepairDueDate(defect.defect.repair_due_date, defect.defect.priority, 'add');

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }
    
    return (
      <React.Fragment>
        <Container className={ classes.containerWithAppBar }>
          <Grid container spacing={3}>
            <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
          </Grid>
          <Box>
            <Grid container spacing={3} className={ classes.contentHeaderContainer }>
              <PageSubtitle object_type="defect" object_id={ defect.defect.short_id !== 'PENDING' ? defect.defect.short_id : this.state.inspection.client_short_name + '-' + defect.defect.id + '(T)' } page_action={ 'Review' } col_width={11} />
              <Grid item xs={1}>
              {
                ((('inspection_status' in defect) && ((defect.inspection_status.length > 0) || (defect.inspection_status === true))) || (('reassessed_status' in defect) && (defect.reassessed_status !== false))) ?
                <IconButton aria-label="settings" onClick={ this._goToDefectEdit } className={ classes.sectionIconButton } disabled>
                  <Edit color="primary" className={ classes.disabledActionButton } />
                </IconButton> : 
                <IconButton aria-label="settings" onClick={ this._goToDefectEdit } className={ classes.sectionIconButton }>
                  <Edit color="primary" className={ classes.addActionButton } />
                </IconButton>
              }
              </Grid>
            </Grid>
            {
              ( defect.hasOwnProperty('track_change_status') ) ? <Box mt={2}>
                  <Paper className={ classes.pageNotification }>
                    <Grid item xs={12}>                  
                      <Typography variant="body2" component="p" className={ clsx( classes.hasIcon, classes.pageNotificationText ) }><WarningRounded /> You have requested the Track be changed to <b>{ defect.new_track_name }</b></Typography>
                    </Grid>
                  </Paper>
                </Box> : ''
            }
            <Paper>
              <Grid container spacing={3} className={ classes.paperGrid }>              
                <Grid item xs={9} className={ classes.headerRow }>
                  <Typography variant="h5" component="h3" >{ this.state.asset_class }</Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    { this.state.track }                 
                  </Typography>
                  <Typography gutterBottom variant="h6" component="h4" className={ classes.hasIcon }>
                    {
                      ( defect.defect.defect_status === 3 ) || ( defect.defect.defect_status === 5 ) || ( repairDateOverdueStatus.status === false && defect.defect.defect_status !== 7 ) ?
                        <ErrorOutline className={ classes.colorRed } /> :
                      (( defect.defect.defect_status === 2 ) ?
                        <CheckCircleOutline className={ classes.colorGreen } /> : <RadioButtonCheckedOutlined className={ classes.colorGrey } />)
                    } { ( repairDateOverdueStatus.status === false && defect.defect.defect_status !== 7 ) ? 'Overdue' : this.state.status }
                  </Typography>
                </Grid>
                <Grid item xs={3} className={ clsx( classes.headerRow, classes.alignRight ) }>
                  <PrioritySymbol colour={ this.state.pcolor } name={ this.state.priority.split(' - ')[0] } />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography gutterBottom variant="h6" component="h4" className={ classes.detailsHeader }>Reported</Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={ clsx(classes.hasIcon, classes.detailsContent) }><Today /> { reportedDate ? reportedDate.getDate().toString().padStart(2, '0') + '/' + (reportedDate.getMonth() + 1).toString().padStart(2, '0') + '/' + reportedDate.getFullYear() : 'UNK' }</Typography>
                </Grid> 
                <Grid item xs={6} sm={4}>
                  <Typography gutterBottom variant="h6" component="h4" className={ classes.detailsHeader }>Repair Due</Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={ clsx(classes.hasIcon, classes.detailsContent) }><Today /> { repairDate ? repairDate.getDate().toString().padStart(2, '0') + '/' + (repairDate.getMonth() + 1).toString().padStart(2, '0') + '/' + repairDate.getFullYear() : 'N/A' }</Typography>
                </Grid> 
                {
                  (( defect.defect.priority !== 6 /* M */) && ( defect.defect.priority !== 5 /* P4 */)) ? 
                  <Grid item xs={6} sm={4}>
                    <Typography gutterBottom variant="h6" component="h4" className={ classes.detailsHeader }>Reassessed</Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={ classes.detailsContent }>{ defectReassessCount }</Typography>
                  </Grid> : ''
                }
                <Grid item xs={12} sm={6}>
                  <Typography gutterBottom variant="h6" component="h4" className={ classes.hasIcon }><MyLocationOutlined /> { Helper.formatKms(defect.defect.kms_start.toString().trim()) }km { ( defect.defect.kms_finish && ( defect.defect.kms_start !== defect.defect.kms_finish ) ) ? 'to ' + Helper.formatKms(defect.defect.kms_finish.toString().trim()) + 'km' : '' }</Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={ classes.detailsContentFine }> Lat/Lng: { defect.defect.latitude ? defect.defect.latitude : '-' } / { defect.defect.longitude ? defect.defect.longitude : '-'}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h6" component="h4" className={ classes.detailsHeader }>Description</Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={ classes.detailsContent }>{ defect.defect.description }</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h6" component="h4" className={ classes.detailsHeader }>Maintenance Required</Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={ classes.detailsContent }>{ defect.defect.maintenance_required ? defect.defect.maintenance_required : 'Not specified.'}</Typography>
                </Grid>
                <Grid item xs={12}>
                {
                  this.renderDefectInspectButtons(classes, defect)
                }
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box mt={3} mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={11}>
                <Typography gutterBottom variant="h5" component="h2">Notes</Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="settings" onClick={ this._goToDefectNoteAdd } className={ classes.sectionIconButton }>
                  <Add color="primary" className={ classes.addActionButton } />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box mb={5}>
          {
            defect.defect_notes.length ?
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={ classes.accordionSummary }
              >
                <Typography className={ classes.heading }>Notes ({ defect.defect_notes.length })</Typography>
              </AccordionSummary>
              <AccordionDetails className={ classes.accordionDetails }>
                <Grid container spacing={3}>
                {
                  defect.defect_notes.map((n, index) =>
                    <Grid item xs={12} key={ 'defect-note-' + index }>
                      <Grid container justifyContent="center">
                        <Card border="info" className={ clsx( classes.fullWidth, classes.cardWrapper ) }>
                          <CardHeader                   
                            avatar={
                              <Avatar aria-label="Defect Note" className={ classes.noteAvatar }>
                                { n.created_by.split(' ')[0][0] + n.created_by.split(' ')[1][0] }
                              </Avatar>
                            }
                            title={ n.defect_note.title }
                            subheader={ Helper.friendlyDate(n.defect_note.created_on) }
                            className={ classes.noteHeader }
                            action={
                              ((n.hasOwnProperty('uuid')) && (n.uuid !== defect.inspection_status) && (n.uuid !== defect.reassessed_status)) ? 
                              <IconButton aria-label="settings" onClick={ () => this._goToDefectNoteEdit(n.uuid) }><Edit color="primary" className={ classes.noteActionButton } /></IconButton> : ''                          
                            }
                          />
                          <CardContent>
                          { 
                            n.defect_note.defect_note.split("\n").map((text, index) => (
                              <Typography variant="body2" color="textSecondary" component="p" className={ classes.noteContentTypography } key={ defect.defect.short_id + '-' + index }>
                                { text }
                              </Typography>
                            ))
                          }
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  )
                }
                </Grid>
              </AccordionDetails>
            </Accordion> :
            <Grid item xs={12}>
              <Paper>
                <List>
                  <ListItem color="textPrimary">No Defect Notes</ListItem>
                </List>
              </Paper>
            </Grid>
          }
          </Box>
          <Box mt={3} mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={11}>
                <Typography gutterBottom variant="h5" component="h2">Photos</Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="settings" onClick={ this._handleAddPhoto } className={ classes.sectionIconButton }>
                  <Add color="primary" className={ classes.addActionButton } />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box mb={5}>
          {
            this.state.defectPhotos.length ? 
            <Accordion mb={10} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1b-content"
                id="panel1b-header"
                className={ classes.accordionSummary }
              >
                <Typography className={classes.heading}>Photos ({ this.state.defectPhotos.length })</Typography>
              </AccordionSummary>
              <AccordionDetails className={ classes.accordionDetails }>
                <Grid item xs={12}>
                {
                  this.state.defectPhotos.length ?                       
                    <Grid container spacing={3} ref={ this.photoGallery } className={ classes.photoGridContainer }>
                    {
                      this.state.defectPhotos.map((i) => this.renderImage(defect.id, i.image_file)) 
                    }
                    </Grid> : 
                    <Paper>
                      <List>
                        <ListItem color="textPrimary">No Photos</ListItem>
                      </List>
                    </Paper>
                }
                </Grid>
              </AccordionDetails>
            </Accordion> : 
            <Grid item xs={12}>
              <Paper>
                <List>
                  <ListItem color="textPrimary">No Photos</ListItem>
                </List>
              </Paper>
            </Grid>
          }
          </Box>
        </Container>
        <AppBar position="fixed" color="primary" className={ classes.appBar }> 
          <BottomNavigation showLabels>
            <BottomNavigationAction label="Prev Defect" icon={<SkipPrevious />} onClick={ this._skipDefectPrev } />
            <BottomNavigationAction label="Back" icon={<Train />} onClick={ this._goToTrack } />
            <BottomNavigationAction label="Next Defect" icon={<SkipNext />} onClick={ this._skipDefectNext } />
          </BottomNavigation>
        </AppBar>
      </React.Fragment>    
    );
  }
}

export default withRouter(withStyles(useStyles)(Defect));