import React from "react";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Container, Grid, MenuItem, TextField, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import { FormValidation } from "./services/FormValidation";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";

const useStyles = theme => ({
  fullWidth: {
    width: '100%'
  }
});

class DefectAdd extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      inspectionId: this.props.match.params.inspectionId,
      defectId: null,
      site: null,
      inpsection: {},
      area: 0,
      priority: 0,
      defect_status: 1,
      asset_class: 0,
      kms_start: '',
      kms_finish: '',
      latitude: 0,
      longitude: 0,
      description: '',
      maintenance_required: '',
      date_reported: null,
      repair_due_date: null,
      priorities: [],
      areas: [],
      asset_classes: [],
      isLoading: true,
      formFields: [ 'asset_class', 'area', 'priority', 'defect_status', 'kms_start', 'kms_finish', 'description', 'maintenance_required', 'repair_due_date', 'latitude', 'longitude' ],
      formErrors: {}
    };
    
    this._getData = this._getData.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleRepairDateChange = this._handleRepairDateChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleGpsUpdate = this._handleGpsUpdate.bind(this);
    this._geoLocationSuccess = this._geoLocationSuccess.bind(this);
    this._validateInput = this._validateInput.bind(this);
  }

  _geoLocationSuccess(position) {
    //console.log("Latitude is :", position.coords.latitude);
    //console.log("Longitude is :", position.coords.longitude);

    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    this.setState({
      latitude: latitude,
      longitude: longitude
    });
  }

  _handleGpsUpdate() {    

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    Helper.getCurrentLocation(this._geoLocationSuccess, error);
  }

  _getData() {    
    this._handleGpsUpdate();

    Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      
      const getDropdownData = Promise.all([
        Service.getAll('Priorities'),
        Service.getAllFromIndex('Tracks', 'siteIndex', parseInt(inspection.site)),
        Service.getAllFromIndex('Assets', 'siteIndex', 0),
        Service.getAllFromIndex('Assets', 'siteIndex', parseInt(inspection.site))        
      ]);

      getDropdownData.then((values) => {

        this.setState({
          site: inspection.site,
          inspection: inspection,
          id: this.props.match.params.inspectionId + '-' + (inspection.defects.length + 1),
          short_id: 'PENDING',
          area: parseInt(this.props.match.params.trackId),
          priority: 0,
          defect_status: 1,
          asset_class: 0,
          priorities: values[0],
          areas: values[1],
          asset_classes: values[2].concat(values[3])
        });
      });
    });
  }

  componentDidMount() {
    if ("geolocation" in navigator) {
      console.log("Available");
    } else {
      console.log("Not Available");
    }

    this._getData();
  }

  _forceUpdate() {
    this.setState({ isLoading: false });
  }

  _validateInput(name, value) {
    //console.log('checking => ' + name + ':' + value);
    let errors = {};
    var testResult = false;
    
    switch ( name ) {
      case 'asset_class':
      case 'area':
      case 'priority':
      case 'defect_status':
        testResult = FormValidation.requireSelect(name, value);
        errors[name] = testResult ? "" : "You must select a value.";
        break;
      case 'kms_start':
        testResult = FormValidation.validateNumber(name, value) && FormValidation.requireText(name, value);
        errors[name] = testResult ? "" : "Kms Start is not valid.";
        break;
      case 'kms_finish':
        testResult = FormValidation.validateNumber(name, value) || value === '';
        errors[name] = testResult ? "" : "Kms Finish is not valid.";
        break;
      case 'repair_due_date':
        testResult = FormValidation.validateDate(name, value);
          
        errors[name] = testResult ? "" : "Repair Due Date is not valid.";
        
        if (testResult) {
          let validationStatus = FormValidation.validateDefectRepairDueDate(value, this.state.priority, 'add');
          testResult = validationStatus['status'];
          errors[name] = testResult ? "" : validationStatus['msg'];
        }
          
        break;
      case 'description':
        testResult = FormValidation.requireTextArea(name, value);
        errors[name] = testResult ? "" : "Description cannot be empty.";
        break;
      case 'maintenance_required':
        testResult = FormValidation.requireTextArea(name, value);
        errors[name] = testResult ? "" : "Maintenance Required cannot be empty.";
        break;
      case 'latitude':
      case 'longitude':
        testResult = FormValidation.validateNumber(name, value) || value === '';
        errors[name] = testResult ? "" : "Not a valid coordinate value.";
        break;
      default:
        testResult = true;
        errors[name] = "";
        break;
    }
    //console.log(testResult);
    
    return errors;
  }

  _handleChange(e) {
    const { name, value } = e.target;
    let error = this._validateInput(name, value);

    if (( name === 'kms_start' ) || ( name === 'kms_finish' ))
      this.setState({ [name]: Helper.formatKms(value), formErrors: { ...this.state.formErrors, [name]: error[name] } });
    else if ( name === 'priority' ) {
      if (this.state.repair_due_date === null) { 
        if ((value !== 5 /* P4 */ ) && (value !== 6 /* M */ )) {
          let repair_due_date = Helper.priorityBasedRepairDate(value);
          this.setState({ [name]: value, repair_due_date: repair_due_date, formErrors: { ...this.state.formErrors, [name]: error[name], 'repair_due_date': "" } });
        } else 
          this.setState({ [name]: value, formErrors: { ...this.state.formErrors, [name]: error[name] } });      
      } else { // Repair Due Date !== null
        if ((value !== 5 /* P4 */ ) && (value !== 6 /* M */ )) {
          let repair_due_date = Helper.priorityBasedRepairDate(value);
          this.setState({ [name]: value, repair_due_date: repair_due_date, formErrors: { ...this.state.formErrors, [name]: error[name], 'repair_due_date': "" } });      
        } else
          this.setState({ [name]: value, repair_due_date: null, formErrors: { ...this.state.formErrors, [name]: error[name], 'repair_due_date': "" } });      
      }
    } else
      this.setState({ [name]: value, formErrors: { ...this.state.formErrors, [name]: error[name] } });
  }

  _handleRepairDateChange(dateObject) {
    let error = this._validateInput('repair_due_date', dateObject);
    this.setState({ repair_due_date: dateObject, formErrors: { ...this.state.formErrors,'repair_due_date': error['repair_due_date'] } });
  }

  _handleCancel() {
    if ( this.props.match.params.trackId !== '0')
      this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects');
    else
      this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/');
  }

  _handleSave() {
    let errors = FormValidation.validateForm(this.state.formFields, this.state, this._validateInput);
    
    if( errors.count === 0 ) {
      let inspection = this.state.inspection;
      let defect = {
        id: this.state.id,
        short_id: this.state.short_id,
        asset_class: this.state.asset_class,
        area: this.state.area,
        defect_status: this.state.defect_status,
        priority: this.state.priority,
        kms_start: Helper.formatKms(this.state.kms_start),
        kms_finish: Helper.formatKms(this.state.kms_finish),
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        description: this.state.description,
        maintenance_required: this.state.maintenance_required,
        date_reported: Helper.toISODateString(new Date()),
        repair_due_date: this.state.repair_due_date,
        app_tmp_id: this.state.id
      };
      
      let defect_count =  this.state.inspection.defects.length;
      var repair_due_date = this.state.repair_due_date;
      
      if (repair_due_date instanceof Date && !isNaN(repair_due_date)) {
        repair_due_date = Helper.toISODateString(this.state.repair_due_date);
        defect.repair_due_date = repair_due_date;
      }

      var uuid = Helper.generateUUID();

      inspection.defects.push({
        area: this.state.area,
        defect: defect,
        defect_images: [],
        defect_notes: [],
        uuid: uuid
      });
      
      inspection.defect_count = defect_count + 1;

      inspection.defects.sort(function(a, b) {
        if (parseFloat(a.defect.kms_start) > parseFloat(b.defect.kms_start))
          return 1;
        else if (parseFloat(a.defect.kms_start) < parseFloat(b.defect.kms_start))
          return -1;
        else
          return 0;
      });

      if ( inspection.defect_order_reverse )
        inspection.defects = inspection.defects.reverse();

      if ( !inspection.tracks.hasOwnProperty(this.state.area) ) { // Create new tracking state for Track
        let track = this.state.areas.filter((track) => track.id === this.state.area);
        inspection.tracks[this.state.area] = {
          id: parseInt(this.state.area),
          name: track[0].name,
          defect_order: [this.state.id],
          defect_order_reverse: false,
          inspected: []
        }
      } else { // Rebuild the track defect_order list to include the new defect
        const filteredDefects = inspection.defects.filter((d) => d.area === parseInt(this.state.area));

        inspection.tracks[this.state.area].defect_order = [];

        for (let i = 0; i < filteredDefects.length; ++i) {
          inspection.tracks[this.state.area].defect_order.push(filteredDefects[i].defect.id);
        }
      }

      Service.put('Inspections', inspection);

      Service.put('Changelog', {
        uuid: uuid,
        change_type: 'newdefect',
        action_datetime: Helper.generateActionDateTime(),
        inspection: this.props.match.params.inspectionId,
        defect: this.state.id,
        asset_class: parseInt(this.state.asset_class),
        area: parseInt(this.state.area),
        defect_status: parseInt(this.state.defect_status),
        kms_start: Helper.formatKms(this.state.kms_start),
        kms_finish: Helper.formatKms(this.state.kms_finish),
        priority: parseInt(this.state.priority),
        description: this.state.description,
        maintenance_required: this.state.maintenance_required,
        repair_due_date: repair_due_date,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        app_tmp_id: this.state.id,
        imgdata: []
      }).then((status) => {
        //console.log(status);
        //let nextDefectId = new URLSearchParams(this.props.location.search).get('next');

        /*if ( this.props.match.params.trackId === '0' ) 
          this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/');
        else if ( nextDefectId && nextDefectId !== 'null' ) 
          this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + nextDefectId);
        else
          this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects');*/
          
        this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.state.area + '/defects/' + this.state.id);
      });
    } else {
      this.setState({ formErrors: { ...errors.msgs }});
      alert('This form has errors.');
    }
  }

  render() {
    
    // const { classes } = this.props;
    let { inspection, priorities, areas, asset_classes } = this.state;

    if (!inspection) {
      return <CircularProgress />;
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }
    
    return (
      <Container spacing={3}>
        <Grid container spacing={3}>
          <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
        </Grid>
        <form>
          <Grid container spacing={3}>
            <PageSubtitle object_type="defect" object_id="PENDING" page_action="New Defect" col_width={12}/>
            <Grid item xs={12}>
              <TextField
                select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
                required
                name="defect_status"
                value={ parseInt(this.state.defect_status) || 1 }
                onChange={ this._handleChange }
                label="Status"
                {...(this.state.formErrors["defect_status"] && { error: true, helperText: this.state.formErrors["defect_status"] })}
              >
                <MenuItem value="1">
                  Pending
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                margin="normal"
                fullWidth
                disabled={ (this.props.match.params.trackId === '0') ? false : true }
                required
                name="area"
                value={ parseInt(this.state.area) }
                onChange={ this._handleChange }
                label="Track"
                {...(this.state.formErrors["area"] && { error: true, helperText: this.state.formErrors["area"] })}
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                {
                  areas.map(i => <MenuItem value={ i.id } key={ "area-" + i.id }>{ i.name }</MenuItem>)
                }
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                margin="normal"
                fullWidth
                name="asset_class"
                value={ parseInt(this.state.asset_class) }
                onChange={ this._handleChange }
                label="Asset Class"
                {...(this.state.formErrors["asset_class"] && { error: true, helperText: this.state.formErrors["asset_class"] })}
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                {
                  asset_classes.map(i => <MenuItem value={ i.id } key={ "asset-" + i.id }>{ i.name }</MenuItem>)
                }
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                labelid="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                name="priority"
                value={ parseInt(this.state.priority) }
                onChange={ this._handleChange }
                label="Priority"
                {...(this.state.formErrors["priority"] && { error: true, helperText: this.state.formErrors["priority"] })}
              >
                <MenuItem value="0">
                  <em>None</em>
                </MenuItem>
                {
                  priorities.map(i => <MenuItem value={ i.id } key={ "priority-" + i.id }>{ i.name }</MenuItem>)
                }
              </TextField>
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                id="outlined-textarea"
                label="Latitude"
                variant="outlined"
                margin="normal"
                ref={ this.formLatitude }
                name="latitude"
                onChange={ this._handleChange }
                value={ this.state.latitude }
                {...(this.state.formErrors["latitude"] && { error: true, helperText: this.state.formErrors["latitude"] })}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="outlined-textarea"
                label="Longitude"
                variant="outlined"
                margin="normal"
                ref={ this.formLongitude }
                name="longitude"
                onChange={ this._handleChange }
                value={ this.state.longitude }
                {...(this.state.formErrors["longitude"] && { error: true, helperText: this.state.formErrors["longitude"] })}
              />
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                id="outlined-textarea"
                label="Start (kms)"
                variant="outlined"
                margin="normal"
                required
                ref={ this.formKmsStart }
                name="kms_start"
                onChange={ this._handleChange }
                {...(this.state.formErrors["kms_start"] && { error: true, helperText: this.state.formErrors["kms_start"] })}
              />
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                id="outlined-textarea"
                label="End (kms)"
                variant="outlined"
                margin="normal"
                ref={ this.formKmsEnd }
                name="kms_finish"
                onChange={ this._handleChange }
                {...(this.state.formErrors["kms_finish"] && { error: true, helperText: this.state.formErrors["kms_finish"] })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Description"
                variant="outlined"
                margin="normal"
                multiline
                required
                minRows={4}
                ref={ this.formDescription }
                name="description"
                onChange={ this._handleChange }
                fullWidth
                {...(this.state.formErrors["description"] && { error: true, helperText: this.state.formErrors["description"] })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Maintenance Required"
                variant="outlined"
                margin="normal"
                multiline
                required
                minRows={4}
                ref={ this.formMaintenanceRequired }
                name="maintenance_required"
                onChange={ this._handleChange }
                fullWidth
                {...(this.state.formErrors["maintenance_required"] && { error: true, helperText: this.state.formErrors["maintenance_required"] })}
              />
            </Grid>

            <Grid item xs={8}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant="outlined"
                  margin="normal"
                  fullWidth
                  InputAdornmentProps={{ position: "start" }}
                  id="date-picker-dialog"
                  label="Repair Due"
                  format="dd/MM/yyyy"
                  value={ this.state.repair_due_date }
                  onChange={ this._handleRepairDateChange }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputProps={{ readOnly: true }} 
                  disabled={ ([ 5 /* P4 */, 6 /* M */ ].includes(this.state.priority)) ? true : false }
                  {...(this.state.formErrors["repair_due_date"] && { error: true, helperText: this.state.formErrors["repair_due_date"] })}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._handleSave }>Save</Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

export default withRouter(withStyles(useStyles)(DefectAdd));