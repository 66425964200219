import React from "react";
import { Container, Grid, Card, CardHeader, CardContent, Avatar, Typography, Button, Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { withRouter, Link } from "react-router-dom";
import { Service } from './services/DBService';
import PageTitle from './PageTitle'

class InspectionVerified extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      confirmClearStatus: false,
      inspection: {}
    }

    this._getInspection = this._getInspection.bind(this);
    this._toggle = this._toggle.bind(this);
    this._clearInspectionStatus = this._clearInspectionStatus.bind(this);
  }

  _getInspection(inspectionId) {
    Service.get('Inspections', parseInt(inspectionId)).then((inspection) => {
      this.setState({ 
        inspection: inspection
      });
    });
  }

  _clearInspectionStatus() {
    // update the inspection status
    let inspection_status = this.state.inspection.inspection_status;console.log('IS -> ' + inspection_status);
    Service.deleteKeyFromIndex('Changelog', 'uniqueId', inspection_status);

    let inspection = this.state.inspection;
    inspection.inspection_status = null;
    
    Service.put('Inspections', inspection);

    this.props.history.push("/inspections/" + this.props.match.params.inspectionId + "/tracks/");
  }

  _toggle(event) {console.log(this.state.inspection);
    this.setState({ confirmClearStatus: !this.state.confirmClearStatus });
  }

  componentDidMount() {                                                         
    this._getInspection(this.props.match.params.inspectionId);
  }

  render() {
    
    let { inspection } = this.state;

    if (!inspection) {
      return <CircularProgress />
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }

    return (
      <Container>
        <Grid container spacing={3}>
          <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
          <Grid item xs={12}>
            <Card border="info" my="2rem">
              <CardHeader                   
                avatar={
                  <Avatar aria-label="Inspection Already Verified">
                    <Warning />
                  </Avatar>
                }
                title="Inspection Verified"
                color="danger"
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" component="p">You have already completed and certified this inspection. Do you want to make changes?</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      style={{display:'table'}}
                      value="end"
                      control={
                        <div style={{display:'table-cell'}}>
                          <Checkbox 
                            checked={ this.state.confirmClearStatus } 
                            onClick={ this._toggle } 
                          />
                        </div>
                      }
                      label="Check the box to confirm this action."
                      labelPlacement="end"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" fullWidth onClick={ this._clearInspectionStatus } size="large" disabled={ !this.state.confirmClearStatus }>Clear Inspection Status</Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="default" fullWidth component={Link} to='/inspections' size="large">Cancel</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(InspectionVerified);