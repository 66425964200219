import { Service } from './DBService';

const PROD_API_BASE_URL = "https://www.lycomms.com/appv2/";
const TEST_API_BASE_URL = "https://test.lycomms.com/appv2/";
const STAGING_API_BASE_URL = "https://staging.lycomms.com/appv2/";
const DEV_API_BASE_URL = "http://localhost:8000/lycomms/appv2/";

class ApiService {

  constructor() {
    if (window.location.hostname === 'app.lycomms.com')
      this.api_url = PROD_API_BASE_URL;
    else if (window.location.hostname === 'app-test.lycomms.com')
      this.api_url = TEST_API_BASE_URL;
    else if (window.location.hostname === 'app-staging.lycomms.com')
      this.api_url = STAGING_API_BASE_URL;
    else
      this.api_url = DEV_API_BASE_URL;
  }

  async fetchData() {

    const requestOptions = {
      method: 'GET',
      credentials: 'include'
    };
    
    requestOptions.body = JSON.stringify(requestOptions.body);
    
    return await fetch(this.api_url + 'getdata.json', requestOptions)
    .then((response) => response.json())
    .catch(
      (err) => {
        console.error('fetchData => error');
        console.error(err);
      }
    );
  }

  async postData(body) {

    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      credentials: 'include',
      body: {
        'data': {
          'update': body
        }
      }
    };
              
    requestOptions.body = JSON.stringify(requestOptions.body);
    
    return await fetch(this.api_url + 'processone.json', requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data.status !== 'badaccesskey') {
        const changeKey = Service.getKeyFromIndex('Changelog', 'uniqueId', data.uuid);
        changeKey.then((key) => {
          return Service.delete('Changelog', key);
        });
        return true;
      } else {
        console.error('postData => invalid access key');
        console.error(data.status);
        localStorage.setItem('authenticated', false);
        return false;
      }
    })
    .catch(
      (err) => {
        console.error('postData => error');
        console.error(err);
        return false;
      }
    );
  }

  async verifyAccessKey(accessKey) {
    
    if(accessKey) {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        body: {
          "access_key": accessKey
        }
      };
      
      requestOptions.body = JSON.stringify(requestOptions.body);

      return await fetch(this.api_url + 'verifykey.json', requestOptions)
      .then((response) => response.json())
      .catch(
        (err) => {
          console.error('verifyAccessKey => ' + err);
        }
      );
    }
    
    return false;
  }

  async ping() {
    return await fetch(this.api_url + 'ping')
    .then((response) => response.json())
    .catch(
      (err) => {
        console.error(err);
      }
    );
  }
}

export default new ApiService();