import React from "react";
import { Container, Grid, Card, CardHeader, CardContent, Avatar, Typography, Button, CircularProgress, Box } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { withRouter, Link } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import PageTitle from './PageTitle'

class WorkSafetyConfirmation extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      inspection: {}
    }

    this._getInspection = this._getInspection.bind(this);
  }

  _getInspection(inspectionId) {
    Service.get('Inspections', parseInt(inspectionId)).then((inspection) => {
      this.setState({ 
        inspection: inspection
      });
    });
  }

  componentDidMount() {                                                         
    this._getInspection(this.props.match.params.inspectionId);
  }

  async updateWorkSafetyStatus(inspectionId) {
    // update the work safety status
    let inspection = await Service.get('Inspections', parseInt(inspectionId));
    let completed_date = new Date()

    inspection.worksite_safety_complete = completed_date.toISOString().replace('T', ' ').slice(0, 19);
    //console.log(inspection.worksite_safety_complete);
    Service.put('Changelog', {
      uuid: Helper.generateUUID(),
      change_type: 'newinspectionnote',
      action_datetime: new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
      inspection: inspectionId,
      asset_class: null,
      title: 'Work Site Safety Completed',
      inspection_note: 'Work site safety documentation completed by inspector: ' + completed_date.toString()
    });

    Service.put('Inspections', inspection);

    this.props.history.push("/inspections/" + inspectionId + "/tracks/");
  }

  render() {
    
    let { inspection } = this.state;

    if (!inspection) {
      return <CircularProgress />
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }

    return (
      <Container>
        <Grid container spacing={3}>
          <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
        </Grid>
        <Box my={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card border="info" my="2rem">
                <CardHeader                   
                  avatar={
                    <Avatar aria-label="Work Safety Compliance Check" style={{ backgroundColor: '#000', color: '#fd0' }}>
                      <Warning />
                    </Avatar>
                  }
                  title="Before you Begin..."
                  subheader="Work Safety Check"
                  color="warning"
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary" component="p">Have you completed the all work site safety documentation?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" fullWidth onClick={() => this.updateWorkSafetyStatus(this.props.match.params.inspectionId)} size="large">SIGN WITH DIGITAL SIGNATURE</Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="default" size="large" fullWidth component={Link} to='/inspections'>CANCEL</Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
}

export default withRouter(WorkSafetyConfirmation);