import React from "react";
import { Container, Grid, Typography, TextField, Button, CircularProgress, MenuItem } from '@material-ui/core';
import { Train } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import { FormValidation } from "./services/FormValidation";
import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";
import PrioritySymbol from "./PrioritySymbol";
import clsx from  'clsx';

const useStyles = theme => ({
  fullWidth: {
    width: '100%'
  },
  alignRight: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
  headerRow: {
    'padding-bottom': '0px'
  },
  detailsHeader: {
    'font-size': '1em',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.54)' 
  },
  detailsContent: {
    'font-size': '1.25em',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.65)'
  },
  hasIcon: {
    'vertical-align': 'middle',
    display: 'flex',
    'align-items': 'center',
    gap: '6px'
  }
});

class DefectTrackChangeRequest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      areas: [],
      priority: '',
      pcolor: '',
      inspectionId: this.props.match.params.inspectionId,
      inspection: undefined,
      defect: undefined,
      new_area: 0,
      username: '',
      formFields: [ 'new_area' ],
      formErrors: {}
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._changeTrackRequest = this._changeTrackRequest.bind(this);
    
    Service.getAll('User').then((user) => { 
      this.state.username = user[0].first_name + ' ' + user[0].last_name;
    });
  }

  _validateInput(name, value) {
    
    let errors = {};
    var testResult = false;

    switch ( name ) {
      case 'new_area':
        testResult = FormValidation.requireSelect(name, value);
        errors[name] = testResult ? "" : "You must select a value.";
        break;
      default:
        
    }

    return errors;
  }

  componentDidMount() {

    Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      const defectIdx = inspection.defects.findIndex((defect) => defect.defect.id.toString() === this.props.match.params.defectId);

      const variableLookups = Promise.all([        
        Service.getAllFromIndex('Tracks', 'siteIndex', parseInt(inspection.site)),
        Service.get('Priorities', inspection.defects[defectIdx].defect.priority)
      ]);

      variableLookups.then((values) => {  
        const areaIdx = values[0].findIndex((area) => area.id === inspection.defects[defectIdx].area);

        let current_track_name = values[0][areaIdx].name;

        values[0].splice(areaIdx, 1);

        this.setState({ 
          inspection: inspection,
          defect_idx: defectIdx,
          defect: inspection.defects[defectIdx],
          track_name: current_track_name,
          areas: values[0],
          priority: values[1].name, 
          pcolor: values[1].colour,
        });

      });
    });
  }

  _handleChange(e) {
    const { name, value } = e.target; 
    let error = this._validateInput(name, value);
    
    this.setState({ [name]: value, formErrors: { ...this.state.formErrors, [name]: error[name] } });
  }

  _handleCancel() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
  }

  _changeTrackRequest() {
    let errors = FormValidation.validateForm(this.state.formFields, this.state, this._validateInput);
    
    if( errors.count === 0 ) {

      let inspection = this.state.inspection;
      let defect = this.state.defect;
      let defect_idx = this.state.defect_idx;

      if ((!('track_change_status' in defect)) || (('track_change_status' in defect) && (defect.track_change_status === false))) {
        let dbChanges = [];
        
        let newTrackIdx = this.state.areas.findIndex((area) => area.id === this.state.new_area);
        let new_track_name = this.state.areas[newTrackIdx].name;

        dbChanges.concat(
          Service.put('Changelog', {
            uuid: Helper.generateUUID(),
            change_type: 'defect_update',
            action_datetime: Helper.generateActionDateTime(),
            inspection: this.props.match.params.inspectionId,
            defect: this.props.match.params.defectId,
            item: 'area',
            value: this.state.new_area
          })
        );

        let uuid = Helper.generateUUID();

        defect.track_change_status = uuid;
        defect.new_track_name = new_track_name;
        defect.defect_changed = true;

        this.setState({ defect: defect });

        inspection.defects[defect_idx] = defect;

        dbChanges.concat(
          Service.put('Inspections', inspection)
        );

        const defectChanges = Promise.all(dbChanges);

        defectChanges.then((status) => {
          this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
        });
      } else {
        alert('A track change has already been requested for this Defect.');
      }
    } else {
      this.setState({ formErrors: { ...errors.msgs }});
      alert('This form has errors.');
    }
  }

  render() {

    let { inspection, defect, areas } = this.state
    const { classes } = this.props;

    if (!defect) {
      return <CircularProgress />;
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }
    
    return (
      <Container>
        <Grid container spacing={3}>
          <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
          <PageSubtitle object_type="defect" object_id={ defect.defect.short_id !== 'PENDING' ? defect.defect.short_id : inspection.client_short_name + '-' + defect.defect.id + '(T)' } page_action={ 'Request Track Change' } />    
          <Grid item xs={9} sm={8}>
            <Typography gutterBottom variant="h6" component="h4" className={ classes.detailsHeader }>Current Track</Typography>
            <Typography variant="h6" component="h3" gutterBottom className={ classes.hasIcon }><Train color="primary" /> { this.state.track_name }</Typography>
          </Grid>
          <Grid item xs={3} sm={4} className={ clsx( classes.headerRow, classes.alignRight ) }>
            <PrioritySymbol colour={ this.state.pcolor } name={ this.state.priority.split(' - ')[0] } />
          </Grid>
          <Grid item xs={12}>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    select
                    labelid="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="new_area"
                    value={ parseInt(this.state.new_area) }
                    onChange={ this._handleChange }
                    label="New Track"
                    {...(this.state.formErrors["new_area"] && { error: true, helperText: this.state.formErrors["new_area"] })}
                  >
                    <MenuItem value="0">
                      <em>None</em>
                    </MenuItem>
                    {
                      areas.map(i => <MenuItem value={ i.id } key={ "area-" + i.id }>{ i.name }</MenuItem>)
                    }
                  </TextField>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._changeTrackRequest }>Continue</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
          </Grid>
        </Grid>
      </Container> 
    );
  }
}

export default withRouter(withStyles(useStyles)(DefectTrackChangeRequest));