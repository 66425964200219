import React from "react";
import { Container, Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";

class WorkOrderVerify extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      workOrderId: this.props.match.params.workOrderId,
      workorder: {},
      wo_comment: ''
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleVerifyComplete = this._handleVerifyComplete.bind(this);
    this._handleRecommendRework = this._handleRecommendRework.bind(this);
  }

  _handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  _handleCancel() {
    this.props.history.push('/workorders/' + this.props.match.params.workOrderId);
  }

  _handleVerifyComplete() {
    this._handleSave('Verify Complete');
  }

  _handleRecommendRework() {
    this._handleSave('Recommend Rework');
  }

  _handleSave(recommendation) {

    let uuid = Helper.generateUUID();
    let workorder = this.state.workorder;

    workorder.inspection_status = uuid;
    workorder.verify = {
      'uuid': uuid,
      'created_on': new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
      'recommendation': recommendation,
      'wo_comment': this.state.wo_comment
    };

    Service.put('WorkOrders', workorder);

    Service.put('Changelog', {
      uuid: uuid,
      change_type: 'workordercomment',
      action_datetime: new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
      work_order_id: this.props.match.params.workOrderId,
      inspection: this.state.workorder.inspection,
      name: recommendation,
      wo_comment: this.state.wo_comment
    }).then((status) => {
      console.log(status);
      this.props.history.push('/workorders/');
    });
  }

  componentDidMount() {   
    Service.get('WorkOrders', parseInt(this.props.match.params.workOrderId)).then((workorder) => {
      this.setState({ workorder: workorder });
    });
  }

  render() {
    let { workorder } = this.state

    if (!workorder) {
      return <CircularProgress />
    }

    return (
      <Container>
        <Grid container spacing={3}>
          <PageTitle object_type="work_order" object_id={ 'WO-' + workorder.id } page_action={ workorder.name } />
          <PageSubtitle object_type="work_order" object_id={  'WO-' + workorder.id  } page_action={ 'Verify Work Order' } />
          <Grid item xs={12}>
            <form>
              <div>
                <TextField
                  id="outlined-textarea"
                  label="Comments"
                  variant="outlined"
                  margin="normal"
                  multiline
                  minRows={8}
                  name="wo_comment"
                  value={ this.state.wo_comment }
                  onChange={ this._handleChange }
                  fullWidth
                />
              </div>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._handleVerifyComplete }>Verify Complete</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" fullWidth size="large" onClick={ this._handleRecommendRework }>Recommend Rework</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(WorkOrderVerify);