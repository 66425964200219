import React from "react";
import { withRouter } from "react-router";
import {
  Redirect
} from "react-router-dom";

class RequireAuth extends React.Component {

  render() {
    if (localStorage.getItem('authenticated') === 'true') 
      return ( this.props.children );
    else
      return ( <Redirect to="/Login" /> );
  }
}

export default withRouter(RequireAuth);