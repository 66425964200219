import React from "react";
import { Grid, Typography } from '@material-ui/core';
import { Today, EventAvailableRounded, DateRangeRounded } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import clsx from  'clsx';


const useStyles = theme => ({
  root: {
    display: 'inline-flex',
    gap: '6px'
  },
  pageContainer: {
    'padding-top': theme.spacing(1),
    'padding-bottom': 0,
    'padding-left': theme.spacing(2),
    'padding-right': theme.spacing(2),
    'background-color': '#fff',
    'margin-bottom': theme.spacing(2),
    '& .MuiGrid-item': {
      'padding-top': theme.spacing(1),
      'padding-bottom': 0,
      'padding-left': theme.spacing(2),
      'padding-right': theme.spacing(2)
    }
  },
  inspectionDetailsContainer: {
    '& .MuiGrid-item': {
      'padding-top': 0,
      'padding-bottom': theme.spacing(1),
      'padding-left': theme.spacing(2),
      'padding-right': theme.spacing(2)
    }
  },
  title: {
    'vertical-align': 'middle',
    'align-items': 'center',
    'padding-bottom': 0,
    'margin-bottom': 0,
    'font-size': '1.8rem'
  },
  subtitle: {
    'vertical-align': 'top',
    'align-items': 'baseline',
    color: theme.palette.secondary.main
  },
  object: {
    color: theme.palette.secondary.main
  },
  icon: {
    color: theme.palette.primary.main
  },
  divider: {
    color: theme.palette.secondary.main,
    'font-size': '0.875rem!important'
  },
  page_action: {
    color: theme.palette.primary.main,
    'font-size': '0.875rem!important',
    'text-transform': 'uppercase'
  },
  inspectionDetailsHeader: {
    'display': 'inline-flex',
    'align-items': 'center',
    'font-size': '0.8rem',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.54)',
    '& > b': {
      'font-weight': 500,
      color: 'rgba(0, 0, 0, 0.95)',
    },
    '& > .MuiSvgIcon-root': {
      'font-size': '1rem!important',
      color: '#1a383b',
      'margin-right': '5px'
    }
  }
});

class PageTitle extends React.Component {

  render() {

    const { classes } = this.props;
    let my_icon = ''

    switch ( this.props.object_type ) {
      /*case 'inspection':
        my_icon = <Search className={ classes.icon } />;
        break;
      case 'work_order':
        my_icon = <Assignment className={ classes.icon } />;
        break;*/
      default:

    }

    var planned_date = '';
    var scheduled_date = '';

    if ((this.props.object_type === 'inspection') && (this.props.page_data.planned_date)) {
      planned_date = this.props.page_data.planned_date.getDate().toString().padStart(2, '0') + '/' + (this.props.page_data.planned_date.getMonth() + 1).toString().padStart(2, '0') + '/' + this.props.page_data.planned_date.getFullYear();
      scheduled_date = this.props.page_data.scheduled_date.getDate().toString().padStart(2, '0') + '/' + (this.props.page_data.scheduled_date.getMonth() + 1).toString().padStart(2, '0') + '/' + this.props.page_data.scheduled_date.getFullYear();
    }
    
    return (
      <Grid container className={ classes.pageContainer } spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom className={ clsx(classes.root, classes.title) }>
            { my_icon } <span className={ classes.object }>{ this.props.object_id }</span>
          </Typography>
          <Typography variant="h6" component="h4" gutterBottom className={ classes.subtitle }>
            {
              this.props.page_action !== '' ? 
                <React.Fragment>
                  <span className={ classes.page_action }>{ this.props.page_action }</span>
                </React.Fragment>
                : ''
            }
          </Typography>
        </Grid>
      {
        (this.props.object_type === 'inspection') ?
          <Grid container className={ classes.inspectionDetailsContainer }>
            <Grid item xs={6} sm={4}>
              <Typography className={ classes.inspectionDetailsHeader }><Today /> Planned:&nbsp;<b>{ planned_date }</b></Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={ classes.inspectionDetailsHeader }><EventAvailableRounded /> Scheduled:&nbsp;<b>{ scheduled_date }</b></Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography className={ classes.inspectionDetailsHeader }><DateRangeRounded /> Iteration / Tolerance:&nbsp;<b>{ this.props.page_data.iteration_days + '/' + this.props.page_data.tolerance_days }</b></Typography>
            </Grid>
          </Grid> : ''
      }
      </Grid>
    );
  }
}

export default withStyles(useStyles)(PageTitle);