import React from "react";
import { Container, Grid, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import { Today } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import { FormValidation } from "./services/FormValidation";
import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";
import PrioritySymbol from "./PrioritySymbol";
import clsx from  'clsx';

const useStyles = theme => ({
  fullWidth: {
    width: '100%'
  },
  alignRight: {
    display: 'flex',
    'justify-content': 'flex-end'
  },
  headerRow: {
    'padding-bottom': '0px'
  },
  detailsHeader: {
    'font-size': '1em',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.54)' 
  },
  detailsContent: {
    'font-size': '1.25em',
    'font-weight': 400,
    color: 'rgba(0, 0, 0, 0.65)'
  },
  hasIcon: {
    'vertical-align': 'middle',
    display: 'flex',
    'align-items': 'center',
    gap: '6px'
  }
});

class DefectReassessment extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      priority: '',
      pcolor: '',
      inspectionId: this.props.match.params.inspectionId,
      inspection: undefined,
      defect: undefined,
      reassess_justification: '',
      username: '',
      formFields: [ 'reassess_justification' ],
      formErrors: {}
    };

    this._nextDefectIdx = this._nextDefectIdx.bind(this);
    this._goToInspection = this._goToInspection.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._reassessDefect = this._reassessDefect.bind(this);
    
    Service.getAll('User').then((user) => { 
      this.state.username = user[0].first_name + ' ' + user[0].last_name;
    });
  }

  _validateInput(name, value) {
    //console.log('checking => ' + name + ':' + value);
    let errors = {};
    var testResult = false;

    switch ( name ) {
      case 'reassess_justification':
        testResult = FormValidation.requireTextArea(name, value);
        errors[name] = testResult ? "" : "Justification is a required field.";
        break;
      default:
        
    }
    //console.log(testResult);

    return errors;
  }

  componentDidMount() {

    Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      const defectIdx = inspection.defects.findIndex((defect) => defect.defect.id.toString() === this.props.match.params.defectId);

      const variableLookups = Promise.all([        
        Service.get('Priorities', inspection.defects[defectIdx].defect.priority)
      ]);

      variableLookups.then((values) => {      
        this.setState({ 
          inspection: inspection,
          defect_idx: defectIdx,
          defect: inspection.defects[defectIdx],
          priority: values[0].name, 
          pcolor: values[0].colour,
        });
      });
    });
  }

  _nextDefectIdx(step = 1) {    
    let nextDefectIdx = this.state.inspection.tracks[this.props.match.params.trackId].defect_order.findIndex((defect) => defect.toString() === this.props.match.params.defectId) + step;

    if ( (nextDefectIdx !== -1) && ( this.state.inspection.tracks[this.props.match.params.trackId].defect_order[nextDefectIdx] ) )
      return this.state.inspection.tracks[this.props.match.params.trackId].defect_order[nextDefectIdx];
    else
      return null; // No more defects
  }

  _goToInspection() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/');
  }

  _handleChange(e) {
    const { name, value } = e.target; 
    let error = this._validateInput(name, value);
    
    this.setState({ [name]: value, formErrors: { ...this.state.formErrors, [name]: error[name] } });
  }

  _handleCancel() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
  }

  _reassessDefect() {
    let errors = FormValidation.validateForm(this.state.formFields, this.state, this._validateInput);
    
    if( errors.count === 0 ) {

      let inspection = this.state.inspection;
      let defect = this.state.defect;
      let defect_idx = this.state.defect_idx;

      if ((!('reassessed_status' in defect)) || (('reassessed_status' in defect) && (defect.reassessed_status === false))) {
        let dbChanges = [];
        
        let reassessed_repair_date = Helper.priorityBasedRepairDate(defect.defect.priority);
        const timestamp_str =  ' -- ' + new Date().toString();
        
        defect.defect.repair_due_date = reassessed_repair_date

        dbChanges.concat(
          Service.put('Changelog', {
            uuid: Helper.generateUUID(),
            change_type: 'defect_update',
            action_datetime: Helper.generateActionDateTime(),
            inspection: this.props.match.params.inspectionId,
            defect: this.props.match.params.defectId,
            item: 'repair_due_date',
            value: Helper.toISODateString(reassessed_repair_date)
          })
        );

        let uuid = Helper.generateUUID();

        defect.reassessed_status = uuid;
        defect.defect_notes = [{
          'uuid': uuid,
          'created_by': this.state.username,
          'defect_note': {
            'created_on': Helper.generateActionDateTime(),
            'defect_note_title': 'Defect Reassessed',
            'defect_note': "Inspected - Reassessed \n Justification: \n\n" + this.state.reassess_justification
          }
        }].concat(defect.defect_notes);

        if ( parseInt(defect.defect.defect_status) === 5 /* Overdue */) {
          defect.defect.defect_status = 2 /* Valid */;

          dbChanges.concat(
            Service.put('Changelog', {
              uuid: Helper.generateUUID(),
              change_type: 'defect_update',
              action_datetime: Helper.generateActionDateTime(),
              inspection: this.props.match.params.inspectionId,
              defect: this.props.match.params.defectId,
              item: 'defect_status',
              value: defect.defect.defect_status
            })
          );
        }

        this.setState({ defect: defect });

        defect.defect.repair_due_date = Helper.toISODateString(reassessed_repair_date);

        inspection.defects[defect_idx] = defect;
        inspection.tracks[this.props.match.params.trackId].inspected.push(this.props.match.params.defectId);

        dbChanges.concat(
          Service.put('Inspections', inspection)
        );
        
        dbChanges.concat(
          Service.put('Changelog', {
            uuid: uuid,
            change_type: 'newdefectnote',
            action_datetime: Helper.generateActionDateTime(),
            inspection: this.props.match.params.inspectionId,
            defect: this.props.match.params.defectId,
            defect_note_title: 'Defect Reassessed',
            defect_note: "Inspected - Reassessed \n Justification: \n\n" + this.state.reassess_justification + "\n\n" + timestamp_str
          })
        );

        const defectChanges = Promise.all(dbChanges);

        defectChanges.then((status) => {

          let nextDefectId = this._nextDefectIdx();
  
          if ( nextDefectId !== null)
            this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' +  + nextDefectId );
          else 
            this._goToInspection();
        });
      } else {
        alert('The Defect has already been reassessed.');
      }
    } else {
      this.setState({ formErrors: { ...errors.msgs }});
      alert('This form has errors.');
    }
  }

  render() {

    let { inspection, defect } = this.state
    const { classes } = this.props;

    if (!defect) {
      return <CircularProgress />;
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }
    
    let repairDate = defect.defect.repair_due_date ? new Date(defect.defect.repair_due_date) : null;
    let reassessedDate = Helper.priorityBasedRepairDate(defect.defect.priority);

    return (
      <Container>
        <Grid container spacing={3}>
          <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
          <PageSubtitle object_type="defect" object_id={ defect.defect.short_id !== 'PENDING' ? defect.defect.short_id : inspection.client_short_name + '-' + defect.defect.id + '(T)' } page_action={ 'Reassess Defect' } />
          <Grid item xs={6} sm={4}>
            <Typography gutterBottom variant="h6" component="h4" className={ classes.detailsHeader }>Current Repair Due</Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={ clsx(classes.hasIcon, classes.detailsContent) }><Today /> { repairDate ? repairDate.getDate().toString().padStart(2, '0') + '/' + (repairDate.getMonth() + 1).toString().padStart(2, '0') + '/' + repairDate.getFullYear() : 'N/A' }</Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography gutterBottom variant="h6" component="h4" className={ classes.detailsHeader }>Reassessed Repair Due</Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={ clsx(classes.hasIcon, classes.detailsContent) }><Today /> { reassessedDate ? reassessedDate.getDate().toString().padStart(2, '0') + '/' + (reassessedDate.getMonth() + 1).toString().padStart(2, '0') + '/' + reassessedDate.getFullYear() : 'N/A' }</Typography>
          </Grid>
          <Grid item xs={3} sm={4} className={ clsx( classes.headerRow, classes.alignRight ) }>
            <PrioritySymbol colour={ this.state.pcolor } name={ this.state.priority.split(' - ')[0] } />
          </Grid>
          <Grid item xs={12}>
            <Typography>Please enter Reassessment Justification (e.g. measurement taken with no change, no deterioration etc.)</Typography>
            <form>
              <div>
                <TextField
                  id="outlined-textarea"
                  label="Justification"
                  variant="outlined"
                  margin="normal"
                  required
                  multiline
                  minRows={8}
                  name="reassess_justification"
                  value={ this.state.reassess_justification }
                  onChange={ this._handleChange }
                  fullWidth
                  {...(this.state.formErrors["reassess_justification"] && { error: true, helperText: this.state.formErrors["reassess_justification"] })}
                />
              </div>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._reassessDefect }>Continue</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
          </Grid>
        </Grid>
      </Container> 
    );
  }
}

export default withRouter(withStyles(useStyles)(DefectReassessment));