import React from "react";
import { Card, CardHeader, CardContent, Avatar, IconButton, CircularProgress, Typography, Divider } from '@material-ui/core';
import { CheckCircle, PlayCircleOutline } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import { Link, withRouter } from 'react-router-dom';
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';

const useStyles = theme => ({
  root: {
    'text-decoration': 'none',
  },
  description: {
    'text-overflow': 'ellipsis',
    overflow: 'hidden',
    'white-space': 'nowrap',
  },
  defectHeader: {
    'padding-bottom': '0px!important'
  },
  defectTitle: {
    'font-size': '1.25rem!important'
  },
  actionButton: {
    'font-size': '2.75rem!important'
  }
});

class InspectionTrackDefectList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      priorities: [],
      asset_classes: []
    }
    
    this._getData = this._getData.bind(this);
  }

  generateURI(inspection_id, track_id, defect_id) {
    return "/inspections/" + inspection_id + "/tracks/" + track_id + "/defects/" + defect_id;
  }

  _getData() {
    Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      const variableLookups = Promise.all([    
        Service.getAll('Priorities'),
        Service.getAllFromIndex('Assets', 'siteIndex', 0),
        Service.getAllFromIndex('Assets', 'siteIndex', parseInt(inspection.site))  
      ]);

      variableLookups.then((values) => {
        this.setState({
          priorities: values[0],
          asset_classes: values[1].concat(values[2])
        });
      });
    });
  }

  componentDidMount() {
    this._getData();
  }

  render() {
    
    const { classes } = this.props;
    const { priorities, asset_classes } = this.state;

    if (this.props.defects.length === 0) {
      return <CircularProgress />;
    }
    
    const lastDefectId = this.props.defects[this.props.defects.length - 1].defect.id

    if (priorities.length === 0) {
      return <CircularProgress />;
    }
    
    return (
      <React.Fragment>
        {Object.keys(this.props.defects).map(key => {
          let d = this.props.defects[key].defect;
          let priority = priorities[parseInt(d.priority) - 1];
          let asset = asset_classes.find(obj => {
            return obj.id === d.asset_class
          });
          let kms_start = d.kms_start !== null ? Helper.formatKms(d.kms_start.toString().trim()) + 'km' : '';
          let kms_finish = (d.kms_finish !== null) && (d.kms_finish !== '') ? Helper.formatKms(d.kms_finish.toString().trim()) + 'km' : '';

          return (
            <React.Fragment key={d.id}>
              <Card component={Link} className={ classes.root } to={this.generateURI(this.props.match.params.inspectionId, this.props.match.params.trackId, d.id)}>
                <CardHeader
                  avatar={
                    <Avatar aria-label="priority" style={{ backgroundColor: priority.colour || '' }}>
                      { priority.name.split(' - ')[0] }
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings">
                    {
                      (this.props.defects[key].inspection_status || this.props.defects[key].reassessed_status || this.props.defects[key].defect.short_id === 'PENDING') ? 
                      <CheckCircle color="primary" className={ classes.actionButton } /> : <PlayCircleOutline className={ classes.actionButton } />
                    } 
                    </IconButton>
                  }
                  classes={{ title: classes.defectTitle }}
                  className={ classes.defectHeader }
                  title={ d.short_id !== 'PENDING' ? d.short_id : this.props.inspection.client_short_name + '-' + d.id + '(T)' }
                  subheader={ asset.name + ' | ' + kms_start + ( ((kms_finish !== '') && (kms_start !== kms_finish)) ? ' - ' + kms_finish : '' ) }
                />
                <CardContent>
                  <Typography className={ classes.description } variant="body2" color="secondary">
                  { d.description } 
                  </Typography>
                </CardContent>
              </Card>
              {
                d.id !== lastDefectId ?
                  <Divider variant={'middle'} /> : ''
              }
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(InspectionTrackDefectList));