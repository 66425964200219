import React from "react";
import { List, ListItem, Card, CardHeader, CardContent, Avatar, IconButton, CircularProgress, Typography, Divider, Grid } from '@material-ui/core';
import { Search, CheckCircle, PlayCircleOutline, Today } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import { Link, withRouter } from 'react-router-dom';

const useStyles = theme => ({
  root: {
    'text-decoration': 'none',
    [theme.breakpoints.down('xs')]: {
      '& .MuiCardHeader-avatar': {
        'display': 'none'
      }
    }
  },
  inspectionAvatar: {
    'background-color': '#009966',
    color: '#fff'
  },
  inspectionHeader: {
    [theme.breakpoints.up('sm')]: {
      'padding-bottom': '0px'
    },
    [theme.breakpoints.down('xs')]: {
      'padding-bottom': '8px',
      'background-color': '#009966'
    }
  },
  inspectionTitle: {
    [theme.breakpoints.up('sm')]: {
      'font-size': '1.25rem!important',
      display: 'inline-flex',
      gap: '6px',
      'flex-direction': 'row'
    },
    [theme.breakpoints.down('xs')]: {
      'font-size': '1rem!important',
      'flex-direction': 'column'
    }
  },
  inspectionId: {
    'font-size': '1.25rem!important',
    color: '#c1c1c1',
    'white-space': 'nowrap',
    [theme.breakpoints.down('xs')]: {
      color: '#fff'
    }
  },
  inspectionDivider: {
    'font-size': '1.25rem!important',
    color: '#1a383b',
    [theme.breakpoints.down('xs')]: {
      'display': 'none'
    }
  },
  inspectionName: {
    [theme.breakpoints.up('sm')]: {
      'font-size': '1.25rem!important',
    },
    [theme.breakpoints.down('xs')]: {
      'font-size': '1rem!important',
    }
  },
  inspectionSubHeader: {    
    [theme.breakpoints.down('xs')]: {
      'font-size': '0.9rem!important',
      color: '#fff'
    }
  },
  descriptionBlock: {
    'padding': '8px 16px 24px',  
    [theme.breakpoints.down('xs')]: {
      'padding': '24px 16px'
    }
  },
  inspectionFeature: {
    'vertical-align': 'middle',
    display: 'inline-flex',
    'align-items': 'center',
    gap: '6px',
    'white-space': 'nowrap',
    'font-size': '1rem',
    [theme.breakpoints.up('sm')]: {
      'margin-right': theme.spacing(2)
    }, 
    [theme.breakpoints.down('xs')]: {
      'padding-top': '4px',
      'margin-right': theme.spacing(1)
    }
  },
  actionButton: {
    'font-size': '2.75rem!important',
    [theme.breakpoints.down('xs')]: {
      color: '#fff'
    }
  }
});

class InspectionList extends React.Component {

  generateURI(id, safety_status, inspection_status) {
    if (safety_status === null) 
      return "/inspections/" + id + '/worksafety';
    else if (inspection_status !== null) 
      return "/inspections/" + id + '/verified';
    else
      return "/inspections/" + id + '/tracks';
  }

  render() {
    if (!this.props.inspections) {
      return <CircularProgress />;
    }

    const { classes } = this.props;

    if(this.props.inspections.length) {
      const lastInspectionId = this.props.inspections[this.props.inspections.length - 1].id
      
      return (
        <React.Fragment>
          {this.props.inspections.map(i => {
            return (
              <React.Fragment key={i.id}>
                <Card component={Link} className={ classes.root } to={this.generateURI(i.id, i.worksite_safety_complete, i.inspection_status)}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="Inspection Icon" className={ classes.inspectionAvatar }>
                        <Search />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="Go to Inspection">
                      {
                        i.inspection_status ?  
                        <CheckCircle color="primary" className={ classes.actionButton } /> : <PlayCircleOutline className={ classes.actionButton } />
                      } 
                      </IconButton>
                    }
                    classes={{ title: classes.inspectionTitle }}
                    className={ classes.inspectionHeader }
                    title={ 
                      <React.Fragment>
                        <Typography className={ classes.inspectionId }>{ 'IN-' + i.id }</Typography><Typography className={ classes.inspectionDivider }> | </Typography><Typography className={ classes.inspectionName }>{ i.name }</Typography> 
                      </React.Fragment>
                    }
                    subheader={ 
                      <Typography className={ classes.inspectionSubHeader }>{ i.site_name + ', ' + i.client_name }</Typography>
                    }
                  />
                  <CardContent className={ classes.descriptionBlock }>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className={ classes.inspectionFeature } variant="body2" color="secondary">
                          <Today color="secondary" /> { 'Planned: ' + i.planned_date }
                        </Typography>
                        <Typography className={ classes.inspectionFeature } variant="body2" color="secondary">
                          <Today color="secondary" /> { 'Scheduled: ' + i.event_date }
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {
                  i.id !== lastInspectionId ?
                    <Divider variant={'middle'} /> : ''
                }
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    } else {
      return (<List><ListItem>No inspections found.</ListItem></List>)
    }
  }
}

export default withRouter(withStyles(useStyles)(InspectionList));