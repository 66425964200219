import React from "react";
import { Container, Box, Grid, Paper, Typography, IconButton, AppBar, BottomNavigation, BottomNavigationAction, List, ListItem, CircularProgress } from '@material-ui/core';
import { AssignmentReturn, AddLocation, SwapVert } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import InspectionTrackDefectList from './InspectionTrackDefectList';
import { withRouter } from "react-router";
import { Service } from './services/DBService';
import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";
import clsx from  'clsx';

const useStyles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1
  },
  hasIcon: {
    'vertical-align': 'middle',
    display: 'inline-flex',
    'align-items': 'center',
    gap: '6px'
  },
  listTitle: {
    padding: '0 12px!important'
  },
  listDirectionToggle: {
    padding: '0 12px!important',
    'text-align': 'right!important'
  },
  listTitleHeader: {
    'margin-bottom': '0!important'
  },
  listTitleIconButton: {
    'padding': '0 12px 0 12px!important'
  }
});

class InspectionTrackDefects extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      inspection: null,
      defects: [],
      track: ''
    }

    this._getInspectionTrackDefects = this._getInspectionTrackDefects.bind(this);

    this._goToInspection = this._goToInspection.bind(this);
    this._addDefect = this._addDefect.bind(this);
    this._toggleTrackDirection = this._toggleTrackDirection.bind(this);
  }

  _getInspectionTrackDefects(inspectionId) {
    Service.get('Inspections', parseInt(inspectionId)).then((inspection) => {

      if ( !inspection.tracks[this.props.match.params.trackId].hasOwnProperty('defect_order_reverse') ) {       
        inspection.tracks[this.props.match.params.trackId].defect_order_reverse = false;
        inspection.tracks[this.props.match.params.trackId].inspected = [];
      }

      const filteredDefects = inspection.defects.filter((d) => d.area === parseInt(this.props.match.params.trackId));
      
      inspection.tracks[this.props.match.params.trackId].defect_order = [];

      for (let i = 0; i < filteredDefects.length; ++i) {
        inspection.tracks[this.props.match.params.trackId].defect_order.push(filteredDefects[i].defect.id);
      }

      const variableLookups = Promise.all([
        Service.get('Tracks', parseInt(this.props.match.params.trackId)),
      ]);

      variableLookups.then((values) => {
        this.setState({ 
          inspection: inspection, 
          defects: filteredDefects,
          track: values[0].name 
        });
      });

      Service.put('Inspections', inspection);
    });
  }

  _toggleTrackDirection() {
    let defects = this.state.defects.reverse();
    let inspection = this.state.inspection;

    inspection.defect_order_reverse = !inspection.defect_order_reverse;
    inspection.defects = inspection.defects.reverse();

    this.setState({ inspection: inspection, defects: defects });

    Service.put('Inspections', inspection);
  }

  _goToInspection() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/');
  }

  _addDefect() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/0/add');
  }

  componentDidMount() {
    this._getInspectionTrackDefects(this.props.match.params.inspectionId);
  }

  render() {
    let { inspection, defects } = this.state;
    const { classes } = this.props;

    if (!inspection) {
      return <CircularProgress />;
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }

    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={3}>
            <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
            <PageSubtitle object_type="track" object_id={ this.state.track } page_action={ 'Track Inspection' } />
          </Grid>
          <Box mt={3} mb={10}>
            <Grid container spacing={3}>
              <Grid item xs={9} className={ classes.listTitle }>
                <Typography gutterBottom variant="h5" component="h2" className={ clsx(classes.title, classes.listTitleHeader) }>Defects</Typography>
              </Grid>
              <Grid item xs={3} className={ classes.listDirectionToggle }>
                <IconButton color="secondary" edge="end" className={ clsx(classes.menuButton, classes.listTitleIconButton) } onClick={ this._toggleTrackDirection } aria-label="reverse-toggle"><SwapVert /></IconButton>
              </Grid>
              <Grid item xs={12}>
                <Paper>
                  {
                    (defects.length !== 0) ? <InspectionTrackDefectList inspection={ inspection } defects={ defects } /> : 
                    <List>
                      <ListItem color="textPrimary">No Defects recorded.</ListItem>
                    </List>
                  }
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <AppBar position="fixed" color="primary" className={ classes.appBar }> 
          <BottomNavigation showLabels>
            <BottomNavigationAction label="Back" icon={<AssignmentReturn />} onClick={ this._goToInspection } />
            <BottomNavigationAction label="New Defect" icon={<AddLocation />} onClick={ this._addDefect } />
          </BottomNavigation>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(InspectionTrackDefects));