import React from "react";
import { Container, Grid, Paper, Typography, Button, CircularProgress, Box } from '@material-ui/core';
import { NavigateNext, Sync } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import ApiService from './services/ApiService';
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';

class Home extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      gpsAvailable: false,
      latitude: 0,
      longitude: 0,
      syncItemCount: 0,
      lastSync: localStorage.getItem("last_sync"),
      isLoading: false,
      isOnline: false
    }
    
    this._sync = this._syncToApi.bind(this);
    this._forceSync = this._forceSync.bind(this);
    this._getUserData = this._getUserData.bind(this);
    this._getSyncItemCount = this._getSyncItemCount.bind(this);
    this._goToInspections = this._goToInspections.bind(this);
    this._geoLocationSuccess = this._geoLocationSuccess.bind(this);
    this._checkOnlineStatus = this._checkOnlineStatus.bind(this);

  }

  _forceSync() {
    this._sync();
  }

  async _syncToApi(force=false) {

    this.setState({ isLoading: true });
    //this._checkOnlineStatus();
  
    const changeData = await Service.getAll('Changelog');

    Helper.checkOnlineStatus().then((status) => {
      if (( localStorage.getItem("authenticated") === 'true') && ( status === true )) {
        
        if(changeData.length > 0) {
          const syncActions = Promise.all(
            changeData.map((change, idx) => {            
              return ApiService.postData(change);
            })
          );
          
          syncActions.then((values) => {
            //console.log('server push response values =>');console.log(values);
            if((values.includes(undefined) === false) && (values.includes(false) === false))
              this._getApiData(); // clear and resync to server
            else {
              if(localStorage.getItem("authenticated") === 'false')                
                this.props.history.push('/Login');
              else {
                alert('Error - Some items did not sync. Wait and retry or check WiFi signal.');
                this._getSyncItemCount();
                this.setState({ isLoading: false });
              }
            }
          });
  
        } else {
          this._getApiData(); // clear and resync to server
        }
      } else {
        console.error('Sync Error => Authorisation Refused');               
        this.props.history.push('/Login');
      }
    })
    .catch((error) => {
      alert('Sync Error => Connection Error');
      console.error(error);
    });
  }

  _getUserData() {
    Service.getAll('User').then((user) => { 

      if ( user.length > 0 )
        this.setState({ username: user[0].first_name + ' ' + user[0].last_name });
      else {
        localStorage.setItem('last_sync', null);
        localStorage.setItem('authenticated', false);
        this.setState({ lastSync: null });
      }
    });
  }

  _getSyncItemCount() {
    Service.getAll('Changelog').then((changes) => { 
      this.setState({ syncItemCount: changes.length }) 
    });
    Service.getAll('Inspections').then((inspections) => { 
      var certifiedInspections = 0;//console.log(inspections);

      for ( var inspection in inspections ) {//console.log(inspection);
        if ( inspections[inspection].inspection_status !== null )
          certifiedInspections++;
      }//console.log( 'check => ' + certifiedInspections + ' && ' + this.state.isOnline );

      //if (( changes.length > 0 ) && ((timenow - Date.parse(this.state.lastSync)) < (120 * 60 * 1000)))
      const isOnline = Helper.checkOnlineStatus();
      
      isOnline.then((status) => {
        //console.log(status);
        //this.setState({ isOnline: status.ok, gpsAvailable: (("geolocation" in navigator) && status.ok) });
        
        if (( certifiedInspections > 0 ) && ( status === true ))
          this._sync();
      });

      //this.setState({ syncItemCount: changes.length }) 
      //this.setState({ syncItemCount: certifiedInspections }) 
    });
  }

  _getApiData() {

    if(localStorage.getItem("authenticated") === 'true') {
      
      ApiService.fetchData()
      .then(
        (data) => {
          //console.log(data);

          if (data.status === 'badaccesskey') {
            //console.log('badaccesskey');
            localStorage.setItem('authenticated', false);
            //localStorage.setItem('last_sync', null);
            this.props.history.push('/Login');
          } else {
            Service.eraseDatabase()
            .then((response) => {
              this._initDB(data);
              this._getSyncItemCount();
              this._getUserData();

              localStorage.setItem('last_sync', new Date());
              alert('Data synchronisation complete.')
              this.setState({ lastSync: new Date(), isLoading: false });
            });
          }
        }
      ).catch(
        (err) => {
          console.log(err);
        }
      );
    }
  }

  _initDB(apidata) {
    //console.log(apidata);

    let { user, areas, asset_classes, inspection_set, priorities, statuses, work_order_set } = apidata;

    Service.put('User', {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email
    });

    for (let i = 0; i < areas.length; i++) {
      let area = areas[i];
      Service.put('Tracks', {
        id: area.id,
        name: area.name,
        site: area.site,
        kms_from: area.kms_from,
        kms_to: area.kms_to
      });
    }

    for (let i = 0; i < asset_classes.length; i++) {
      let asset = asset_classes[i];
      Service.put('Assets', {
        id: asset.id,
        name: asset.name,
        site: asset.site != null ? asset.site : 0,
        kms_from: asset.kms,
        kms_to: asset.kms_to,
        latitude: asset.latitude,
        longitude: asset.longitude
      });
    }

    for (let i = 0; i < inspection_set.length; i++) {
      let inspection = inspection_set[i];
      Service.put('Inspections', {
        id: inspection.inspection.id,
        name: inspection.inspection.name,
        client_id: inspection.inspection.client_id,
        client_name: inspection.inspection.client_name,
        client_short_name: inspection.inspection.client_short_name,
        event_date: inspection.inspection.event_date,
        planned_date: inspection.inspection.planned_date,
        iteration: inspection.inspection.iteration_days,
        tolerance: inspection.inspection.tolerance_days,
        site: inspection.inspection.site,
        site_name: inspection.inspection.site_name,
        inspection_status: null,
        skip_p4: inspection.inspection.skip_p4,
        worksite_safety_complete: inspection.inspection.worksite_safety_complete,
        notes: inspection.inspection.notes,
        tracks: inspection.inspection.tracks,
        defects: inspection.inspection_data
      });
    }

    for (let i = 0; i < priorities.length; i++) {
      let priority = priorities[i];
      Service.put('Priorities', {
        id: priority.id,
        name: priority.name,
        client: priority.client,
        colour: priority.colour
      });
    }

    for (let i = 0; i < statuses.length; i++) {
      let status = statuses[i];
      Service.put('Statuses', {
        id: status.id,
        name: status.name,
        colour: status.colour
      });
    }

    for (let i = 0; i < work_order_set.length; i++) {
      let workorder = work_order_set[i];
      Service.put('WorkOrders', {
        id: workorder.id,
        name: workorder.name,
        site: workorder.site,
        inspection: workorder.inspection,
        work_type: workorder.work_type,
        event_date: workorder.event_date,
        signoff_datedue: workorder.signoff_datedue,
        primary_activity: workorder.primary_activity,
        secondary_activity: workorder.secondary_activity,
        work_summary: workorder.work_summary,
        works_as_executed: workorder.works_as_executed,
        images: []
      });
    }
  }

  _goToInspections() {
    this.props.history.push('/inspections/');
  }

  _geoLocationSuccess(position) {
    //console.log("Latitude is :", position.coords.latitude);
    //console.log("Longitude is :", position.coords.longitude);

    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    this.setState({
      latitude: latitude,
      longitude: longitude
    });
  }

  _checkOnlineStatus() {
    const isOnline = Helper.checkOnlineStatus();
    isOnline.then((status) => {
      //console.log(status);
      this.setState({ isOnline: status, gpsAvailable: (("geolocation" in navigator) && status) });
    });
  }

  componentDidMount() {
    this.Timer = setInterval(() => {
      this._checkOnlineStatus();
    }, 1000);

    this._checkOnlineStatus();

    if ( navigator.geolocation ) {

      var options = {
        enableHighAccuracy: true,
        timeout: 6000,
        maximumAge: 30000
      };

      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        alert('Unable to get your location. Please make sure you have location services enabled.');
      }

      if ( this.state.gpsAvailable )
        navigator.geolocation.getCurrentPosition(this._geoLocationSuccess, error, options);
    }
    
    if ( this.state.lastSync === 'null' ) {
      this.setState({ isLoading: true });
      this._getApiData();
    } else {
      this._getUserData();
      this._getSyncItemCount();
    }

    //console.log('sync => ' + this.state.lastSync);
  }

  componentWillUnmount() {
    clearInterval(this.Timer);
  }

  render() {
    //console.log('Home');
    //console.log(this.props.data);
    if(!this.state.isLoading) {
      return (
        <React.Fragment>
          <Container>
            <Paper>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2" gutterBottom>Home</Typography>
                  <Typography variant="h6" component="h3" gutterBottom>{ 'Logged in as ' + (this.state.username ? this.state.username : '') }</Typography>
                </Grid>
              </Grid>
              <Box my={3}>
                <Grid container spacing={3} align="center">
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Version: { this.props.app_version }
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      { this.state.isOnline ? 'Online' : 'Offline' }
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      { this.state.gpsAvailable ? 'GPS Enabled' : 'GPS Disabled' }
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      { this.state.syncItemCount + ' item' + (this.state.syncItemCount !== 1 ? 's' : '') } pending sync
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={ this._forceSync } size="large" className="circular" disabled={ this.state.isOnline ? false : true }><Sync /></Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="secondary" size="large" onClick={ this._goToInspections } disabled={ ( (this.state.isLoading) || (this.state.lastSync === null) ) ? true : false }>Inspections <NavigateNext /></Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Container>
            <Paper>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2" gutterBottom>Home</Typography>
                  <Typography variant="h6" component="h3" gutterBottom></Typography>
                </Grid>
              </Grid>
              <Box my={3}>
                <Grid container spacing={3} align="center">
                  <Grid item xs={12}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Container>
        </React.Fragment>
      );
    }
  }
}

export default withRouter(Home);