class Helpers {
  generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });

    return uuid;
  }

  async checkOnlineStatus() {
    /*return fetch('https://httpstat.us/200')
    .catch(function(error) {
      console.log(error);
      return false; // definitely offline
    });*/
    return navigator.onLine;
  }
        
  calcGPSSeparationKms(lat1,lon1,lat2,lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
    var dLon = this.deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // Distance in km

    return d;
  }

  getCurrentLocation(success, error) {  
    if ( navigator.geolocation ) {

      var options = {
        enableHighAccuracy: true,
        timeout: 6000,
        maximumAge: 30000
      };

      navigator.geolocation.getCurrentPosition(success, error, options);
    }
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  formatKms(value) {
    let kms = parseFloat(value);

    if ( !isNaN(kms) ) {
      let kmsTmp = kms.toString().split('.');

      if ( kmsTmp[1] ) {
        if ( kmsTmp[1].length > 3 )
          return kmsTmp[0] + '.' + kmsTmp[1].slice(0,3);
        else
          return kmsTmp[0] + '.' + kmsTmp[1].padEnd(3, '0');
      } else
        return kmsTmp[0] + '.000';
    }

    return '';
  }

  friendlyDate(input) {
    if(typeof input === 'undefined' || input === null)
        return "";

    //var _date = new Date(input.replace(/-/g,'/').slice(0,10));  
    var _date = new Date(input.replace(/\s/, 'T') + '.000+00:00');
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    
    return '' + days[_date.getDay()] + ', ' + _date.getDate() + ' ' + months[_date.getMonth()]  + ', ' + _date.getFullYear();
  }

  generateActionDateTime() {
    // UTC datetime for consistency with API
    return new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19)
  }

  toISODateString(input) {
    if(typeof input === 'undefined' || input === null)
        return "";
      
    return input.getFullYear() + '-' + (input.getMonth() + 1).toString().padStart(2, '0') + '-' + (input.getDate()).toString().padStart(2, '0');
  }

  toAuDateString(input) {
    if(typeof input === 'undefined' || input === null)
        return "";
      
    return (input.getDate()).toString().padStart(2, '0') + '/' + (input.getMonth() + 1).toString().padStart(2, '0') + '/' + input.getFullYear();
  }

  priorityBasedRepairDate(priority) {

    let today = new Date();
    
    if ( priority === 4 ) {
      today.setFullYear( today.getFullYear(), today.getMonth(), today.getDate() + 365 );
    } else if ( priority === 3 ) {
      today.setFullYear( today.getFullYear(), today.getMonth(), today.getDate() + 28 );
    } else if ( priority === 2 ) {
      today.setFullYear( today.getFullYear(), today.getMonth(), today.getDate() + 7 );
    } else if ( priority === 1 ) {
      today.setFullYear( today.getFullYear(), today.getMonth(), today.getDate() + 1 );
    } else {
      // Return no new date for M, P4
      return null;
    }

    return today;
  }
}

export const Helper = new Helpers()