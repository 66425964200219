import React from "react";
import { Container, Grid, TextField, Button } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import { Camera } from './services/CameraService';
import { FormValidation } from "./services/FormValidation";
import PageTitle from "./PageTitle";

class WorkOrderPhotoAdd extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      photoBtnText: 'Take Photo',
      workOrderId: this.props.match.params.workOrderId,
      workorder: {
        id: '',
        name: ''
      },
      image_src: '',
      image_title: '',
      image_description: '',
      formFields: [ 'image_src', 'image_title', 'image_description' ],
      formErrors: {}
    };
    
    this.hiddenFileInput = React.createRef();

    this._getWorkOrder = this._getWorkOrder.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleAddPhotoClick = this._handleAddPhotoClick.bind(this);
    this._handlePhotoSelect = this._handlePhotoSelect.bind(this);
    this._validateInput = this._validateInput.bind(this);
  }

  _getWorkOrder(workOrderId) {
    Service.get('WorkOrders', parseInt(workOrderId)).then((workorder) => {
      //console.log(workorder);
      this.setState({ workorder: workorder });
    });
  }

  _validateInput(name, value) {
    //console.log('checking => ' + name + ':' + value);
    let errors = {};
    var testResult = false;

    switch ( name ) {
      case 'image_src':
        testResult = FormValidation.requireTextArea(name, value);
        errors[name] = testResult ? "" : "You must select a photo.";
        break;
      case 'image_title':
      case 'image_description':
        testResult = true;
        errors[name] = '';
        break;
      default:
        
    }
    //console.log(testResult);

    return errors;
  }

  _handleChange(e) {
    const { name, value } = e.target;  
    let error = this._validateInput(name, value);

    this.setState({ [name]: value, formErrors: { ...this.state.formErrors, [name]: error[name] } });
  }

  _handleCancel() {
    this.props.history.push('/workorders/' + this.props.match.params.workOrderId);
  }

  _handleSave() {
    let errors = FormValidation.validateForm(this.state.formFields, this.state, this._validateInput);
    
    if( errors.count === 0 ) {
      let workorder = this.state.workorder;

      workorder.images = [{
        'created_on': new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
        'img_title': this.state.image_title,
        'img_desc': this.state.image_description,
        'image_file': this.state.image_src
      }].concat(workorder.images);

      Service.put('WorkOrders', workorder);

      Service.put('Changelog', {
        uuid: Helper.generateUUID(),
        change_type: 'woaddimage',
        action_datetime: new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
        inspection: this.state.workorder.inspection,
        work_order_id: this.props.match.params.workOrderId,
        imgtitle: this.state.image_title,
        imgdesc: this.state.image_description,
        value: this.state.image_src
      }).then((status) => {
        //console.log(status);
        this.props.history.push('/workorders/' + this.props.match.params.workOrderId);
      });
    } else {
      this.setState({ formErrors: { ...errors.msgs }});
      alert('This form has errors.');
    }
  }

  _handleAddPhotoClick() {
    this.hiddenFileInput.current.click();
  }

  _handlePhotoSelect(event) {
    const fileUploaded = event.target.files[0];
    
    Camera.imgToBase64(fileUploaded, (imageSrcBase64) => {
      this.setState({ image_src: imageSrcBase64, photoBtnText: 'Re-take Photo' });
    });
  }

  componentDidMount() {                                                         
    this._getWorkOrder(this.props.match.params.workOrderId);
  }

  render() {
    return (
      <Container>
        <Grid container spacing={3}>
          <PageTitle object_type="work_order" object_id={ 'WO-' + this.state.workOrderId } page_action="Add Photo" />
          <Grid item xs={12}>
            {
              this.state.image_src ?
              <img
                width="100%"
                src={ this.state.image_src }
                alt=""
              /> : <Button onClick={ this._handleAddPhotoClick } ><img src="/images/wide-camera-placeholder.png" style={{ width: '100%' }} alt="Camera Button" /></Button>
            }
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._handleAddPhotoClick }>{ this.state.photoBtnText }</Button>
            <input
              type="file"
              ref={ this.hiddenFileInput }
              onChange={ this._handlePhotoSelect }
              style={{display: 'none'}}
            />
          </Grid>
          <Grid item xs={12}>
            <form>
              <div>
                <TextField
                  id="outlined-textarea"
                  label="Title"
                  variant="outlined"
                  margin="normal"
                  name="image_title"
                  value={ this.state.image_title }
                  onChange={ this._handleChange }
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  id="outlined-textarea"
                  label="Description"
                  variant="outlined"
                  margin="normal"
                  multiline
                  minRows={8}
                  name="image_description"
                  value={ this.state.image_description }
                  onChange={ this._handleChange }
                  fullWidth
                />
              </div>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._handleSave } disabled={ !this.state.image_src }>Save</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(WorkOrderPhotoAdd);