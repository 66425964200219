import React from "react";
import { Container, Grid, Paper, AppBar, BottomNavigation, BottomNavigationAction, Box } from '@material-ui/core';
import { Assignment, Home } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import InspectionList from './InspectionList';
import { Service } from './services/DBService';
import { withRouter } from "react-router";
import PageTitle from "./PageTitle";

const useStyles = theme => ({
  containerWithAppBar: {
    'margin-bottom': theme.spacing(12)
  },
  inspectionListContainer: {
    [theme.breakpoints.down('xs')]: {
      '&:first-child': {
        'border-top-left-radius': '4px'
      }
    }
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  }
});

class Inspections extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      
    }

    this._goToHome = this._goToHome.bind(this);
    this._goToWorkOrders = this._goToWorkOrders.bind(this);
    this._goToSettings = this._goToSettings.bind(this);
    this._getInspections = this._getInspections.bind(this);
  }

  _goToHome() {
    this.props.history.push('/');
  }

  _goToWorkOrders() {
    this.props.history.push('/workorders');
  }

  _goToSettings() {
    this.props.history.push('/settings');
  }

  _getInspections() {
    Service.getAll('Inspections').then((inspections) => {
      inspections.sort((a, b) => {
        let dateA = new Date(a.planned_date);
        let dateB = new Date(b.planned_date);

        return dateA - dateB;
      });
      
      this.setState({ inspections: inspections })
    });
  }

  componentDidMount() {
    this._getInspections();
  }

  render() {
    let { inspections } = this.state;
    const { classes } = this.props;
    
    return (
      <React.Fragment>
        <Container className={ classes.containerWithAppBar }>
          <Grid container spacing={3}>
            <PageTitle object_id='Inspections' page_action='&nbsp;' />
          </Grid>
          <Box my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={ classes.inspectionListContainer }>
                  <InspectionList inspections={ inspections }/>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <AppBar position="fixed" color="primary" className={ classes.appBar }> 
          <BottomNavigation
            showLabels
          >
            <BottomNavigationAction label="Home" icon={<Home />} onClick={ this._goToHome } />
            <BottomNavigationAction label="Work Orders" icon={<Assignment />} onClick={ this._goToWorkOrders } />
          </BottomNavigation>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(Inspections));