import React from "react";
import { Container, Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import { FormValidation } from "./services/FormValidation";
import PageTitle from "./PageTitle";
import PageSubtitle from "./PageSubtitle";

const useStyles = theme => ({
  danger: {
    'margin-top': '3em',
    'border': '3px solid #dc3545',
    color: '#fff',
    'background-color': '#dc3545',
    'font-weight': '600'
  }
});

class DefectNoteEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      inspectionId: this.props.match.params.inspectionId,
      inpsection: {},
      defect: {
        id: '',
        short_id: ''
      },
      defectId: this.props.match.params.defectId,
      note_title: '',
      defect_note: '',
      created_on: '',
      username: '',
      formFields: [ 'defect_note' ],
      formErrors: {}
    };

    this._getData = this._getData.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleDelete = this._handleDelete.bind(this);
    this._validateInput = this._validateInput.bind(this);
    
    Service.getAll('User').then((user) => { 
      this.state.username = user[0].first_name + ' ' + user[0].last_name;
    });
  }

  _getData() {
    Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      const filteredDefects = inspection.defects.filter((defect) => defect.defect.id.toString() === this.props.match.params.defectId);
      const filteredDefectNotes = filteredDefects[0].defect_notes.filter((defect_note) => defect_note.uuid === this.props.match.params.defectNoteId);

      this.setState({
        inspection: inspection,
        defect: filteredDefects[0].defect,
        note_title: (filteredDefectNotes[0].defect_note.hasOwnProperty('note_title')) ? filteredDefectNotes[0].defect_note.note_title : '',
        defect_note: filteredDefectNotes[0].defect_note.defect_note,
        created_on: filteredDefectNotes[0].defect_note.created_on
      });
    });
  }

  componentDidMount() {
    this._getData();
  }

  _validateInput(name, value) {
    //console.log('checking => ' + name + ':' + value);
    let errors = {};
    var testResult = false;

    switch ( name ) {
      case 'defect_note':
        testResult = FormValidation.requireTextArea(name, value);
        errors[name] = testResult ? "" : "Comments cannot be empty.";
        break;
      default:
        
    }
    //console.log(testResult);

    return errors;
  }

  _handleChange(e) {
    const { name, value } = e.target; 
    let error = this._validateInput(name, value);

    this.setState({ [name]: value, formErrors: { ...this.state.formErrors, [name]: error[name] } });
  }

  _handleCancel() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
  }

  _handleDelete() {
    
    if( window.confirm('Are sure you want to delete this note?') === true ) {
      let inspection = this.state.inspection;
      const defectIdx = inspection.defects.findIndex((defect) => defect.defect.id.toString() === this.props.match.params.defectId);
      const defectNoteIdx = inspection.defects[defectIdx].defect_notes.findIndex((defect_note) => defect_note.uuid === this.props.match.params.defectNoteId);

      inspection.defects[defectIdx].defect_notes.splice(defectNoteIdx, 1);
      
      Service.put('Inspections', inspection);

      Service.deleteKeyFromIndex('Changelog', 'uniqueId', this.props.match.params.defectNoteId).then((status) => {
        //console.log(status);
        this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
      });
    }
  }

  _handleSave() {
    let errors = FormValidation.validateForm(this.state.formFields, this.state, this._validateInput);
    
    if( errors.count === 0 ) {
      let inspection = this.state.inspection;
      const defectIdx = inspection.defects.findIndex((defect) => defect.defect.id.toString() === this.props.match.params.defectId);
      const defectNoteIdx = inspection.defects[defectIdx].defect_notes.findIndex((defect_note) => defect_note.uuid === this.props.match.params.defectNoteId);
      const timestamp_str =  ' -- ' + new Date(this.state.created_on + " UTC").toString();
      const defectNoteUuid = Helper.generateUUID();

      inspection.defects[defectIdx].defect_notes[defectNoteIdx] = {        
        'uuid': defectNoteUuid,
        'created_by': this.state.username,
        'defect_note': {
          'created_on': this.state.created_on, // Maintain original creation timestamp. UTC datetime for consistency with API
          'defect_note': this.state.defect_note
        }
      }

      Service.put('Inspections', inspection);

      Service.put('Changelog', {
        uuid: defectNoteUuid,
        change_type: 'newdefectnote',
        action_datetime: this.state.created_on, // Maintain original creation timestamp. UTC datetime for consistency with API
        inspection: this.props.match.params.inspectionId,
        defect: this.props.match.params.defectId,
        defect_note_title: this.state.note_title,
        defect_note: this.state.defect_note + timestamp_str
      }).then((status) => {
        //console.log(status);
        Service.deleteKeyFromIndex('Changelog', 'uniqueId', this.props.match.params.defectNoteId);
        this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks/' + this.props.match.params.trackId + '/defects/' + this.props.match.params.defectId);
      });
    } else {
      this.setState({ formErrors: { ...errors.msgs }});
      alert('This form has errors.');
    }
  }

  render() {
    
    let { inspection, defect } = this.state;
    const { classes } = this.props;

    if (!inspection) {
      return <CircularProgress />;
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }
    
    return (
      <Container>
        <Grid container spacing={3}>
          <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
          <PageSubtitle object_type="defect" object_id={ defect.short_id !== 'PENDING' ? defect.short_id : inspection.client_short_name + '-' + defect.id + '(T)' } page_action="New Note" />
          <Grid item xs={12}>   
            <form>
              <Grid item xs={12}>
                <TextField
                  id="outlined-textarea"
                  label="Comments"
                  variant="outlined"
                  margin="normal"
                  required
                  multiline
                  minRows={8}
                  name="defect_note"
                  value={ this.state.defect_note }
                  onChange={ this._handleChange }
                  fullWidth
                  {...(this.state.formErrors["defect_note"] && { error: true, helperText: this.state.formErrors["defect_note"] })}
                />
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._handleSave }>Save</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" className={ classes.danger } fullWidth size="large" onClick={ this._handleDelete }>Delete</Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withRouter(withStyles(useStyles)(DefectNoteEdit));