import React from "react";
import { Container, Grid, Card, CardHeader, CardContent, Avatar, Typography, Button, FormControlLabel, Checkbox, Paper, Box, CircularProgress } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { Service } from './services/DBService';

class Settings extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isClearing: false,
      confirmDelete: false
    }

    this._toggle = this._toggle.bind(this);
    this._clearApiData = this._clearApiData.bind(this);
  }

  _toggle(event) {
    this.setState({ confirmDelete: !this.state.confirmDelete });
  }

  _clearApiData() {
    //const { cookies } = this.props;
    this.setState({ isClearing: true });

    Service.eraseDatabase()
    .then(
      (response) => {
        //console.log(response);
        /*var daysToExpire = 28;
        let date = new Date();
        date.setTime(date.getTime()+(daysToExpire*24*60*60*1000));*/
        //cookies.set("access_token", null, { domain: 'lycomms.com', path: '/', expires: date, sameSite: 'strict', secure: true });
        //cookies.set("last_sync", null, { domain: 'lycomms.com', path: '/', expires: date, sameSite: 'strict', secure: true });
        localStorage.setItem('authenticated', false);
        localStorage.setItem('last_sync', null);
        this.props.history.push('/Login');
      }
    ).catch(
      (err) => {
        console.log(err);
      }
    );
  }

  render() {
    if(!this.state.isClearing) {
      return (
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card border="info" my="2rem">
                <CardHeader                   
                  avatar={
                    <Avatar aria-label="Confirm Database Clear">
                      <Warning />
                    </Avatar>
                  }
                  title="Erase App Data"
                  subheader="Clear ALL Local Ap Data"
                  color="danger"
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary" component="p">Warning! Clearing your local databases will mean you lose unsaved/non-submitted changes. Are you sure?</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        style={{display:'table'}}
                        value="end"
                        control={
                          <div style={{display:'table-cell'}}>
                            <Checkbox 
                              checked={ this.state.confirmDelete } 
                              onClick={ this._toggle } 
                            />
                          </div>
                        }
                        label="Check the box to confirm the action and then click below to clear all data."
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" fullWidth onClick={ this._clearApiData } size="large" disabled={ !this.state.confirmDelete }>Clear Data</Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      );
    } else {
      return (
        <React.Fragment>
          <Container>
            <Paper>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2" gutterBottom>Clearing Data</Typography>
                  <Typography variant="h6" component="h3" gutterBottom></Typography>
                </Grid>
              </Grid>
              <Box my={3}>
                <Grid container spacing={3} align="center">
                  <Grid item xs={12}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Container>
        </React.Fragment>
      );
    }
  }
}

export default withRouter(Settings);