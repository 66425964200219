import React from "react";
import { Snackbar, Button, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

class UpdateSnackBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: true
    };

    this._handleClose = this._handleClose.bind(this);
    this._handleRestart = this._handleRestart.bind(this);
  }

  _handleClose() {
    this.setState({ open: false })
  }

  _handleRestart() {
    return window.location.reload(true);
  }

  render() {

    return (
      <Snackbar
        id="custom-refresh-bar"
        style={ { display: 'none' } }
        open={ this.state.open }
        onClose={ this._handleClose }
        message="New update available."
        action={
          <React.Fragment>
            <Button color="primary" variant="contained" size="small" onClick={ this._handleRestart }>
              Restart
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={ this._handleClose }
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  }
}

export default UpdateSnackBar;