import React from "react";
import { Container, Box, Grid, Paper, Typography, TextField, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ApiService from './services/ApiService';
import { FormValidation } from "./services/FormValidation";

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      access_token: "",
      formFields: [ 'access_token' ],
      formErrors: {}
    }

    this.formAccessTokenValue = React.createRef();
    this._handleClick = this._handleClick.bind(this);
    //this.verifyAccessToken = this.verifyAccessToken.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._validateInput = this._validateInput.bind(this);
  }

  _validateInput(name, value) {
    //console.log('checking => ' + name + ':' + value);
    let errors = {};
    var testResult = false;

    switch ( name ) {
      case 'access_token':
        testResult = FormValidation.requireText(name, value) && FormValidation.validateAccessToken(name, value);
        errors[name] = testResult ? "" : "Invalid access token.";
        break;
      default:
        
    }
    //console.log(testResult);

    return errors;
  }

  _handleChange(e) {
    const { name, value } = e.target; 
    let error = this._validateInput(name, value);

    this.setState({ [name]: value, formErrors: { ...this.state.formErrors, [name]: error[name] } });
  }
  
  _handleClick(event) {
    let errors = FormValidation.validateForm(this.state.formFields, this.state, this._validateInput);
    
    if( errors.count === 0 ) {
      this.setState({ isLoading: true });

      ApiService.verifyAccessKey(this.formAccessTokenValue.current.value)
      .then(
        (data) => {
          //console.log(data);
          //const { cookies } = this.props;

          /*var daysToExpire = 28;
          var date = new Date();
          date.setTime(date.getTime()+(daysToExpire*24*60*60*1000));*/

          if(data.status !== 'badaccesskey') {
            //cookies.set("access_token", this.formAccessTokenValue.current.value, { domain: 'lycomms.com', path: '/', expires: date, sameSite: 'strict', secure: true });
            //cookies.set("last_sync", null, { domain: 'lycomms.com', path: '/', expires: date, sameSite: 'strict', secure: true });
            localStorage.setItem('authenticated', true);

            let serverUser = document.cookie.replace(/(?:(?:^|.*;\s*)username\s*=\s*"([^;]*)".*$)|^.*$/, "$1");
            //console.log(serverUser + ' !== ' + localStorage.getItem('last_user'));
            if(serverUser !== localStorage.getItem('last_user')) // This should force the Home page to erase the DB if the user changes, else local data should be saved - Ben, 2022-08-17
              localStorage.setItem('last_sync', null);
            
            localStorage.setItem('last_user', serverUser);

            this.props.history.push('/');
          } else {
            console.log('error');
            //cookies.set("access_token", null, { domain: 'lycomms.com', path: '/', expires: date, sameSite: 'strict', secure: true });
            this.setState({ isLoading: false });
            return false;
          }
        }
      ).catch(
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.setState({ formErrors: { ...errors.msgs }});
      alert('Authentication failed.');
    }
  }

  /*componentDidMount() {
    //localStorage.setItem('authenticated', false);
    //localStorage.setItem('last_sync', null);
  }*/

  render() {
    //console.log('Login');
    //console.log(this.props.data);
    return (
      <React.Fragment>
        <Container>
          <Paper>
            <Grid container spacing={3} align="center">
              <Grid item xs={12}>
                <Typography variant="h5" component="h2" gutterBottom>Login</Typography>
                <Typography variant="h6" component="h3" gutterBottom>Access Token</Typography>
              </Grid>
            </Grid>
            <Box my={3}>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Access to LycoMMS data requires an application token. (Four sets of characters in the format XXXX-XXXX-XXXX-XXXX)
                  </Typography>
                  <TextField
                    id="outlined-textarea"
                    label="Access Token"
                    placeholder="XXXX-XXXX-XXXX-XXXX"
                    variant="outlined"
                    name="access_token"
                    inputRef={ this.formAccessTokenValue }
                    onChange={ this._handleChange }
                    {...(this.state.formErrors["access_token"] && { error: true, helperText: this.state.formErrors["access_token"] })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={ this._handleClick } size="large" >Set Token</Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);