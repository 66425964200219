import React from "react";
import { Box, Container, Grid, Typography, TextField, Button, FormControlLabel, Checkbox, Card, CardHeader, CardContent, Avatar, CircularProgress } from '@material-ui/core';
import { Link, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Service } from './services/DBService';
import { Helper } from './services/Helpers';
import PageTitle from './PageTitle';
import PageSubtitle from './PageSubtitle';

const useStyles = theme => ({
  root: {
    'text-decoration': 'none',
  },
  description: {
    'text-overflow': 'ellipsis',
    overflow: 'hidden',
    'white-space': 'nowrap',
  },
  defectHeader: {
    'padding-bottom': '0px!important'
  },
  defectTitle: {
    'font-size': '1.25rem!important'
  },
  actionButton: {
    'font-size': '2.75rem!important'
  }
});

class InspectionConfirmation extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      confirmVerified: false,
      inspectionId: this.props.match.params.inspectionId,
      inspection: {},
      defects: [],
      priorities: [],
      certify_description: ''
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
    this._handleVerifyComplete = this._handleVerifyComplete.bind(this);
    this._handleVerifyIncomplete = this._handleVerifyIncomplete.bind(this);
    this._toggle = this._toggle.bind(this);
  }

  componentDidMount() {   

    Service.get('Inspections', parseInt(this.props.match.params.inspectionId)).then((inspection) => {
      const variableLookups = Promise.all([    
        Service.getAll('Priorities') 
      ]);

      variableLookups.then((values) => {
        this.setState({ 
          inspection: inspection,
          defects: Object.entries(inspection.defects).filter(([id, d]) => (d.hasOwnProperty('inspection_status') === false) && (d.hasOwnProperty('reassessed_status') === false) && (d.defect.short_id !== 'PENDING') ),
          priorities: values[0]
        });
      });
    });
  }

  _toggle(event) {
    this.setState({ confirmVerified: !this.state.confirmVerified });
  }

  _handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  _handleCancel() {
    this.props.history.push('/inspections/' + this.props.match.params.inspectionId + '/tracks');
  }

  _handleVerifyComplete() {
    this._handleSave(true);
  }

  _handleVerifyIncomplete() {
    this._handleSave(false);
  }

  _handleSave(certify_state) {
    //console.log(this.state);
    var note_title = '';

    if ( certify_state )
      note_title = (this.state.certify_description.trim().length > 0) ? 'Certified with Conditions' : 'Certified';
    else
      note_title = 'Not Certified';

    let inspection = this.state.inspection;

    inspection.inspection_status = Helper.generateUUID();
    inspection.verify = {
      'created_on': new Date().toISOString().replace('T', ' ').slice(0, 19),
      'title': note_title,
      'insp_note': this.state.certify_description
    };

    Service.put('Inspections', inspection);

    Service.put('Changelog', {
      uuid: inspection.inspection_status,
      change_type: 'newinspectionnote',
      action_datetime: new Date(Date.now()).toISOString().replace('T', ' ').slice(0, 19), // UTC datetime for consistency with API
      inspection: this.props.match.params.inspectionId,
      title: note_title,
      inspection_note: note_title + ': ' + ((this.state.certify_description !== '') ? this.state.certify_description : 'No comments') + '. By inspector: ' + new Date().toString()
    }).then((status) => {
      //console.log(status);
      this.props.history.push('/');
    });
  }

  render() {
    
    const { classes } = this.props;
    const { inspection, priorities } = this.state;

    if (!inspection) {
      return <CircularProgress />
    }

    let inspectionHeaderData = {
      'planned_date': new Date(inspection.planned_date),
      'scheduled_date': new Date(inspection.event_date),
      'iteration_days': inspection.iteration,
      'tolerance_days': inspection.tolerance
    }

    if (this.state.defects.length === 0) {
      return (
        <Container>
          <Grid container spacing={3}>
            <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="h2">SIGN-OFF INSPECTION</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary" component="p">
                My signature confirms I have inspected the listed infrastructure and certified it as shown as safe for rail operations.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                It also confirms I have the appropriate competencies and I have executed due diligence. 
                Where corrective work is required, appropriate action has been taken and the work required has been recorded.
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Check the box below then click Certify, or add Conditions or Do Not Certify to digitally sign off the Inspection.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                style={{display:'table'}}
                value="end"
                control={
                  <div style={{display:'table-cell'}}>
                    <Checkbox 
                      checked={ this.state.confirmVerified } 
                      onClick={ this._toggle } 
                    />
                  </div>
                }
                label="SIGN WITH DIGITAL SIGNATURE"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12}>
              <form>
                <div>
                  <TextField
                    id="outlined-textarea"
                    label="Conditions (optional)"
                    variant="outlined"
                    margin="normal"
                    multiline
                    minRows={8}
                    name="certify_description"
                    value={ this.state.certify_description }
                    onChange={ this._handleChange }
                    fullWidth
                  />
                </div>
              </form>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth size="large" onClick={ this._handleVerifyComplete } disabled={ !this.state.confirmVerified }>{ this.state.certify_description.length > 0 ? 'Certify with Conditions' : 'Certify' }</Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="secondary" fullWidth size="large" onClick={ this._handleVerifyIncomplete } disabled={ !this.state.confirmVerified }>Do not Certify</Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
            </Grid>
          </Grid>
        </Container> 
      );
    } else {
      return (
        <Container> 
          <Grid container spacing={3}>
            <PageTitle object_type="inspection" object_id={ inspection ? 'IN-' + inspection.id : '' } page_action={ inspection ? inspection.name : '' } page_data={ inspectionHeaderData } />
            <PageSubtitle object_type="track" object_id={ this.state.track } page_action={ 'Inspection Certification' } />         
          </Grid>
          <Box mt={3} mb={10}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="h2">Warning</Typography>
                <Typography variant="body2" color="textSecondary" component="p">The following defect(s) have not been sighted or a defect note made:</Typography>
              </Grid>
              {
                this.state.defects.map((d, index) => {
                  let priority = priorities[parseInt(d[1].defect.priority) - 1];

                  return (
                    <Grid item xs={12} key={ 'defect-unseen-' + index }>
                      <Card border="info" my="2rem">
                        <CardHeader                   
                          avatar={
                            <Avatar aria-label="priority" style={{ backgroundColor: priority.colour || '' }}>
                              { priority.name.split(' - ')[0] }
                            </Avatar>
                          }
                          classes={{ title: classes.defectTitle }}
                          className={ classes.defectHeader }
                          title={ d[1].defect.short_id }
                          subheader={ Helper.friendlyDate(d[1].defect.created_on) }
                        />
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Typography variant="body2" color="textSecondary" component="p">
                                { d[1].defect.description }
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Button variant="contained" color="secondary" fullWidth component={Link} to={ '/inspections/' + this.props.match.params.inspectionId + '/tracks/' + d[1].defect.area + '/defects/' + d[1].defect.id } size="large">Review Defect</Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })
              }
              <Grid item xs={12}>
                <Button variant="contained" color="default" fullWidth size="large" onClick={ this._handleCancel }>Cancel</Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )
    }
  }
}

export default withRouter(withStyles(useStyles)(InspectionConfirmation));