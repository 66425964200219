import React from "react";
import { Container, Grid, Paper, AppBar, BottomNavigation, BottomNavigationAction, Box } from '@material-ui/core';
import { Search, Home } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import WorkOrderList from './WorkOrderList';
import { withRouter } from "react-router";
import { Service } from './services/DBService';
import PageTitle from "./PageTitle";

const useStyles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  hasIcon: {
    'vertical-align': 'middle',
    display: 'inline-flex',
    'align-items': 'center',
    gap: '6px'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
  },
  defectInspected: {
    color: 'green'
  }
});

class WorkOrders extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      
    }

    this._goToHome = this._goToHome.bind(this);
    this._goToInspections = this._goToInspections.bind(this);
    this._goToSettings = this._goToSettings.bind(this);
    this._getWorkOrders = this._getWorkOrders.bind(this);
  }

  _goToHome() {
    this.props.history.push('/');
  }

  _goToInspections() {
    this.props.history.push('/inspections');
  }

  _goToSettings() {
    this.props.history.push('/settings');
  }

  _getWorkOrders(workOrderId) {
    Service.getAll('WorkOrders').then((workorders) => {
      this.setState({ workorders: workorders })
    });
  }

  componentDidMount() {
    this._getWorkOrders();
  }

  render() {
    let { workorders } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Container>
          <Grid container spacing={3}>
            <PageTitle object_id='Work Orders' page_action='' />
          </Grid>
          <Box my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper>
                  <WorkOrderList workorders={ workorders }/>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <AppBar position="fixed" color="primary" className={ classes.appBar }> 
          <BottomNavigation
            showLabels
          >
            <BottomNavigationAction label="Home" icon={<Home />} onClick={ this._goToHome } />
            <BottomNavigationAction label="Inspections" icon={<Search />} onClick={ this._goToInspections } />
          </BottomNavigation>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(useStyles)(WorkOrders));